import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { UserService } from "../user.service";

import type {
  Endpoint,
  IApiResponse,
  IHttpProject,
  IHttpGetProject,
  IHttpCreateProjectResponse,
  IHttpApiUpdateResponse,
  IAddProjectContractor,
  IGetProjectContractor,
  IApiResetDefectsResponse,
  Project,
  ListPrimaryKeyId,
  ICloneProject,
  IHttpCloneProjectResponse,
} from "../../models";

import {
  IEditProject,
  ICreateProject,
  IProjectDefectOrder,
  ProjectUserType,
  ProjectPermission,
  ProjectPrimaryKeyId,
  PrimaryKeyId,
} from "../../models";

import { HTTP_METHOD } from ".";

@Injectable({
  providedIn: "root",
})
export class ProjectApiService {
  private _getProjects = "GetProjects" as Endpoint;
  private _getProject = "GetProject" as Endpoint;
  private _createProject = "CreateProject" as Endpoint;
  private _cloneProject = "CloneProject" as Endpoint;
  private _editProject = "EditProject" as Endpoint;
  private _deleteProject = "DeleteProject" as Endpoint;
  private _addProjectContractor = "AddProjectContractor" as Endpoint;
  private _editProjectContractor = "EditProjectContractor" as Endpoint;
  private _getProjectContractors = "GetProjectContractors" as Endpoint;
  private _removeProjectContractor = "RemoveProjectContractor" as Endpoint;
  private _resetDefectNumbers = "ResetDefectNumbers" as Endpoint;
  private _addProjectEntity = "AddProjectEntity" as Endpoint;
  private _removeProjectEntity = "RemoveProjectEntity" as Endpoint;
  private _editProjectEntity = "EditProjectEntity" as Endpoint;
  private _editLists = "EditLists" as Endpoint;

  /** @ignore */
  constructor(private http: HttpService, private us: UserService) {}

  /**
   * Gets all the projects for the given tenant
   * @returns {Promise<IApiResponse<IHttpProject[]>>} Array of projects data
   */
  async getProjects(): Promise<IApiResponse<IHttpProject[]>> {
    const ids = await this.us.checkAndGetUserIds();
    return this.http.request<IHttpProject[]>(
      HTTP_METHOD.GET,
      this._getProjects,
      { tenantId: ids.tenantId, userId: ids.userId },
      null
    );
  }

  /**
   * Gets the details of a project
   * @param {ProjectPrimaryKeyId} projectId cloud id of project
   * @returns {Promise<IApiResponse<IHttpGetProject>>} Project data
   */
  async getProject(
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IHttpGetProject>> {
    const ids = await this.us.checkAndGetUserIds();
    return this.http.request<IHttpGetProject>(
      HTTP_METHOD.GET,
      this._getProject,
      { projectId, userId: ids.userId, tenantId: ids.tenantId },
      null,
      false
    );
  }

  /**
   * Creates a new project and associated sites
   * @param {ICreateProject} project New project object along with site data
   * @returns {Promise<IApiResponse<ICreateProjectResponse>>} New cloud Ids and lastModified's for each entry
   */
  async createProject(
    project: ICreateProject
  ): Promise<IApiResponse<IHttpCreateProjectResponse>> {
    const userId = await this.us.checkAndGetUserId();
    return this.http.request<IHttpCreateProjectResponse>(
      HTTP_METHOD.POST,
      this._createProject,
      null,
      { project, userId },
      false
    );
  }

  /**
   * Creates a new project and associated sites
   * @param {ICreateProject} project New project object along with site data
   * @returns {Promise<IApiResponse<ICreateProjectResponse>>} New cloud Ids and lastModified's for each entry
   */
  async cloneProject(
    project: ICloneProject,
    projectToCloneId: ProjectPrimaryKeyId,
    copyDefects: boolean
  ): Promise<IApiResponse<IHttpCloneProjectResponse>> {
    const ids = await this.us.checkAndGetUserIds();
    return this.http.request<IHttpCloneProjectResponse>(
      HTTP_METHOD.POST,
      this._cloneProject,
      null,
      {
        tenantId: ids.tenantId,
        userId: ids.userId,
        projectId: projectToCloneId,
        project: {
          name: project.name,
          ref: project.ref,
          address: project.address,
          city: project.city,
          postcode: project.postcode,
          state: project.state,
          country: project.country,
          client: project.client,
          type: project.type,
        },
        copyDefects: copyDefects,
      },
      false
    );
  }

  /**
   * Update the details of an existing project
   * @param {IEditProject} project Updated Project details
   * @returns {Promise<IApiResponse<IHttpApiUpdateResponse>>} Includes the cloud Id and updated lastModified
   */
  async editProject(
    project: IEditProject
  ): Promise<IApiResponse<IHttpApiUpdateResponse>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IHttpApiUpdateResponse>(
      HTTP_METHOD.PUT,
      this._editProject,
      null,
      { tenantId, project, projectId: project.id },
      false
    );
  }

  /**
   * Delete all data related to a project
   * @param {ProjectPrimaryKeyId} projectId cloud Id of project
   * @returns {Promise<IApiResponse>} Success & message
   */
  async deleteProject(projectId: ProjectPrimaryKeyId): Promise<IApiResponse> {
    return this.http.request(
      HTTP_METHOD.DELETE,
      this._deleteProject,
      null,
      { projectId },
      false
    );
  }

  /**
   * Add a relationship between a project and a contractor
   * @param {ProjectPrimaryKeyId} projectId cloud id
   * @param {PrimaryKeyId} contractorId cloud id
   * @returns {Promise<IApiResponse<IAddProjectContractor>>} Success, message,
   * cloudId, projectLastModified
   */
  async addProjectContractor(
    projectId: ProjectPrimaryKeyId,
    contractorId: PrimaryKeyId
  ): Promise<IApiResponse<IAddProjectContractor>> {
    const defaultAssignment = false;
    return this.http.request(
      HTTP_METHOD.POST,
      this._addProjectContractor,
      null,
      { projectId, contractorId, defaultAssignment }
    );
  }

  /**
   * Get the contractors and their project related status
   * @param {ProjectPrimaryKeyId} projectId cloud id
   * @returns {Promise<IApiResponse>} Success & message
   */
  async getProjectContractors(
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IGetProjectContractor[]>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IGetProjectContractor[]>(
      HTTP_METHOD.GET,
      this._getProjectContractors,
      { tenantId, projectId },
      null
    );
  }

  /**
   * Remove the relationship between a project and a contractor
   * @param {ProjectPrimaryKeyId} projectId Cloud Id of the project
   * @param {PrimaryKeyId} contractorId Cloud Id of the contractor
   * @returns {Promise<IApiResponse>} Success & message
   */
  async removeProjectContractor(
    projectId: ProjectPrimaryKeyId,
    contractorId: PrimaryKeyId
  ): Promise<IApiResponse> {
    return this.http.request(
      HTTP_METHOD.DELETE,
      this._removeProjectContractor,
      null,
      { projectId, contractorId }
    );
  }

  /**
   * Modify the defaultAssignment field for a contractor
   * @param {ProjectPrimaryKeyId} projectId
   * @param contractorId
   * @param {boolean} defaultAssignment
   * @returns {Promise<IApiResponse>} Success & message
   */
  async editProjectContractor(
    projectId: ProjectPrimaryKeyId,
    contractorId: PrimaryKeyId,
    defaultAssignment: boolean
  ): Promise<IApiResponse> {
    return this.http.request(
      HTTP_METHOD.PUT,
      this._editProjectContractor,
      null,
      { projectId, contractorId, defaultAssignment }
    );
  }

  /**
   * Resets the defect numbering based on selected order
   * @param order
   * @param {ProjectPrimaryKeyId} projectId
   * @returns
   */
  async resetDefectNumbers(
    order: IProjectDefectOrder,
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IApiResetDefectsResponse>> {
    return this.http.request<IApiResetDefectsResponse>(
      HTTP_METHOD.PUT,
      this._resetDefectNumbers,
      null,
      { order, projectId },
      false
    );
  }

  /**
   * Adds an entity to a project
   * @param {ProjectPrimaryKeyId} projectId
   * @param {number} userId
   * @param {string} permission
   * @param {ProjectUserType} entityType `"User" or "Group"`
   * @returns {Promise<IApiResponse>}
   */
  async addProjectEntity(
    projectId: ProjectPrimaryKeyId,
    userId: number,
    permission: ProjectPermission,
    entityType: ProjectUserType
  ): Promise<IApiResponse<{ cloudId: number }>> {
    return this.http.request(HTTP_METHOD.POST, this._addProjectEntity, null, {
      projectId,
      entityId: userId,
      entityType,
      permission,
    });
  }

  /**
   * Removes an entity from a project
   * @param {number} projectId
   * @param {number} entityId
   * @returns {Promise<IApiResponse>}
   */
  async removeProjectEntity(
    projectId: ProjectPrimaryKeyId,
    entityId: number,
    entityType: ProjectUserType
  ): Promise<IApiResponse> {
    return this.http.request(
      HTTP_METHOD.DELETE,
      this._removeProjectEntity,
      null,
      { projectId, entityId, entityType }
    );
  }

  /**
   * Updates an entity permission against a project
   * @param {ProjectPrimaryKeyId} projectId
   * @param {number} entityId
   * @param {ProjectUserType|string} entityType
   * @param {string} permission
   * @returns {Promise<IApiResponse>}
   */
  async editProjectEntity(
    projectId: ProjectPrimaryKeyId,
    entityId: number,
    entityType: ProjectUserType,
    permission: ProjectPermission
  ): Promise<IApiResponse> {
    return this.http.request(HTTP_METHOD.PUT, this._editProjectEntity, null, {
      projectId,
      entityId,
      entityType,
      permission,
    });
  }

  /**
   * Updates the details of an existing site
   * @param {List.DefectMultiEdit} data Updated site data to send
   * @param {DefectPrimaryKeyId[]} defectIds
   * @param projectId
   * @returns {Promise<IApiResponse<IHttpApiUpdateResponse>>} Cloud Id and updated lastmodified
   *
   * @since W2.2.0
   */
  async bulkEditLists(
    data: Project.ListBulkEdits,
    listIds: ListPrimaryKeyId[]
  ): Promise<IApiResponse<IHttpApiUpdateResponse>> {
    return this.http.request<IHttpApiUpdateResponse>(
      HTTP_METHOD.PUT,
      this._editLists,
      null,
      { ...data, listIds },
      false
    );
  }
}

import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { UserService } from "../user.service";

import type { IApiResponse } from "../../models/http";
import type {
  Endpoint,
  IBillingCreateCustomer,
  IBillingPortal,
  IBillingSession,
  StripeCustomerId,
  StripeSubscriptionId,
} from "../../models";

import { HTTP_METHOD } from ".";

@Injectable({
  providedIn: "root",
})
export class BillingApiService {
  private _createCustomer = "StripeCustomer" as Endpoint;
  private _getSubscription = "StripeSubscription" as Endpoint;
  private _getPortal = "StripePortal" as Endpoint;
  private _getSession = "StripeSession" as Endpoint;

  constructor(private http: HttpService, private us: UserService) {}
  /**
   * Register a new user
   * @param email
   * @param name
   * @param phone
   * @returns {any} Stripe Customer Object
   */
  async createCustomer(
    email: string,
    name: string,
    phone: string
  ): Promise<IApiResponse<IBillingCreateCustomer>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IBillingCreateCustomer>(
      HTTP_METHOD.POST,
      this._createCustomer,
      { tenantId },
      { email, name, phone }
    );
  }
  /**
   * Get subscription from stripe api
   * @param {StripeCustomerId} customerId Stripe Customer Id
   * @returns {string} subscription id, undefined if not found
   */
  async getSubscription(
    customerId: StripeCustomerId
  ): Promise<IApiResponse<StripeSubscriptionId>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<StripeSubscriptionId>(
      HTTP_METHOD.GET,
      this._getSubscription,
      { customerId, tenantId },
      null
    );
  }
  /**
   * Get subscription from stripe api
   * https://stripe.com/docs/billing/subscriptions/integrating-customer-portal#redirect
   * @param {StripeCustomerId} customerId Stripe Customer Id
   * @returns {any} Portal
   */
  async getPortal(
    customerId: StripeCustomerId
  ): Promise<IApiResponse<IBillingPortal>> {
    return this.http.request<IBillingPortal>(
      HTTP_METHOD.POST,
      this._getPortal,
      null,
      {
        customer: customerId,
        return_url: "https://account.defectwise.com.au//options/billing",
      }
    );
  }
  /**
   * Get sesssion from stripe api
   * @param {string} customerId Stripe Customer Id
   * @param {string} productId Stripe Product Id
   * @param {number} quantity number of sub's
   * @param {string} email Customer email
   * @param {string} promoId Stripe promo id
   *
   * @returns {any} Stripe Session
   */
  async getSession(
    customerId: StripeCustomerId,
    productId: string,
    quantity: number,
    email: string,
    promoId?: string
  ): Promise<IApiResponse<IBillingSession>> {
    return this.http.request<IBillingSession>(
      HTTP_METHOD.POST,
      this._getSession,
      null,
      {
        customer: customerId,
        email,
        productId,
        quantity,
        promoId,
      }
    );
  }
}

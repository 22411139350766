import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpInterceptorService } from "./services/http-interceptor.service";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { environment } from "../environments/environment";
import packageJson from "../../package.json";

// https://docs.sentry.io/platforms/javascript/guides/capacitor/
import * as Sentry from "@sentry/browser";
import * as SentryAngular from "@sentry/angular";
import { ServiceWorkerModule } from "@angular/service-worker";

Sentry.init({
  dsn: "https://ec517c893b194984b54cbe1bddb1ae75@o941297.ingest.sentry.io/6601097",
  release: "defectwise-web@" + packageJson.version,
  dist: "1",
  environment: environment.production ? "production" : "development",
  debug: !environment.production,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: environment.production ? 0.5 : 0,
  replaysOnErrorSampleRate: environment.production ? 1.0 : 0,
  replaysSessionSampleRate: environment.production ? 0.1 : 0,
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useValue: SentryAngular.createErrorHandler() },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from "@angular/core";
import type {
  IApiResponse,
  Endpoint,
  ProjectPrimaryKeyId,
  ListPrimaryKeyId,
  PrimaryKeyId,
} from "src/app/models";

import { HTTP_METHOD } from ".";
import { HttpService } from "../http.service";
import { UserService } from "../user.service";

@Injectable({
  providedIn: "root",
})
export class ReportsApiService {
  private _tenantReportOptions = "TenantReportOptions" as Endpoint;
  private _reports = "Reports" as Endpoint;
  private _generateReport = "CreateReport" as Endpoint;
  private _shareReport = "ShareReport" as Endpoint;

  constructor(private http: HttpService, private us: UserService) {}

  /**
   * Gets Tenant Report Options
   * @returns {Promise<IApiResponse<IHttpUser>>} User data
   * @since 1.5.0
   */
  async getReportOptions(): Promise<IApiResponse<ITenantReportOption[]>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<ITenantReportOption[]>(
      HTTP_METHOD.GET,
      this._tenantReportOptions,
      {
        tenantId: tenantId,
      },
      true
    );
  }

  /**
   * Gets Tenant Report Options
   * @returns {Promise<IApiResponse<IHttpUser>>} User data
   * @since 1.5.0
   */
  async putReportOption(
    option: ITenantReportOption
  ): Promise<IApiResponse<_PutReportOptionResult>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request(
      HTTP_METHOD.PUT,
      this._tenantReportOptions,
      {},
      {
        tenantId: tenantId,
        option: {
          tenantOptionId: option.tenantOptionId,
          name: option.name,
          value: option.value,
        },
      },
      true
    );
  }

  /**
   * Gets Tenant Report Options
   * This calls a background service task for all report types.
   * Response is now:
   * ```{
   *   data: {},
   *   headers: {},
   *   status: number,
   *   url: string
   * }```
   * If the request has been accepted, then status === 202
   * therefore, evaluate this as the acceptable response
   * @returns {Promise<IApiResponse>} User data
   * @since 1.5.0
   */
  async generateReport(
    options: IGenerateReportOptions,
    projectCloudId: ProjectPrimaryKeyId
  ): Promise<IApiResponse> {
    const ids = await this.us.checkAndGetUserIds();
    return this.http.request(
      HTTP_METHOD.POST,
      this._generateReport,
      {},
      {
        userId: ids.userId,
        tenantId: ids.tenantId,
        projectId: projectCloudId,
        type: options.type,
        notes: options.notes,
        title: options.title,
        listIds: options.listIds,
        contractorIds: options.contractorIds,
        statusTypes: options.statusTypes,
        priorityTypes: options.priorityTypes,
        quality: options.quality,
        inspectionDate: options.inspectionDate,
        filename: options.filename,
      },
      true
    );
  }

  /**
   * Deletes a report from the server
   * @param reportId
   * @returns
   */
  async deleteReport(reportId: number): Promise<IApiResponse> {
    return this.http.request(
      HTTP_METHOD.DELETE,
      this._reports,
      {},
      {
        reportId: reportId,
      },
      true
    );
  }

  /**
   * Deletes a report from the server
   * @param reportId
   * @returns
   */
  async shareReport(
    reportId: number
  ): Promise<IApiResponse<{ readonly shareId: string }>> {
    return this.http.request(
      HTTP_METHOD.POST,
      this._shareReport,
      {},
      {
        reportId: reportId,
      },
      true
    );
  }

  /**
   * Deletes a report from the server
   * @param reportId
   * @returns
   */
  async deleteShare(reportId: number): Promise<IApiResponse> {
    return this.http.request(
      HTTP_METHOD.DELETE,
      this._shareReport,
      {},
      {
        reportId: reportId,
      },
      true
    );
  }
}

/**
 * Report option sent from server
 * @since 1.5.0
 */
export type ITenantReportOption = {
  readonly tenantOptionId: number;
  readonly name: string;
  readonly value: any;
  /** Available from server, not required for PUT */
  readonly datatype?: "boolean" | "string" | "int";
};

/**
 * Report options to send to server
 * @since 1.5.0
 */
type IGenerateReportOptions = {
  type: "csv" | "pdf" | "docx" | string;
  notes: string;
  title: string;
  /** lists to print */
  listIds: ListPrimaryKeyId[];
  /** contractor ids to print */
  contractorIds: PrimaryKeyId[];
  /** status types to print */
  statusTypes: number[];
  /** priority types to print */
  priorityTypes: number[];
  /** image quality, still useful is report if huge */
  quality: number;
  /** selected date on ui, printed in report */
  inspectionDate: string;
  /** more like file 'Description'? in this context */
  filename: string;
};

interface _PutReportOptionResult {
  optionId: number;
}

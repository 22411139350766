import { Injectable } from "@angular/core";

import { UserService } from "./user.service";
import { ImageCompressorService } from "./image-compressor.service";
import { ANALYTICS_EVENTS, AnalyticsService } from "./analytics.service";

import { ApiService } from "./api/index";

import type {
  IHttpEditDefectPhotoIds,
  IHttpEditDefectDcmIds,
  IDefectContractorAssignment,
  IComment,
  ICommentPhoto,
  IProjectProgressSummaryUpdate,
  IEditDefectPhoto,
  IEditDefectContractor,
  DefectLog,
  DefectLogItem,
  HistoryLogFieldName,
} from "../models";

import {
  Defect,
  ICreateDefect,
  IEditDefect,
  IApiEditDefect,
  ICreateDefectPhoto,
  ListPrimaryKeyId,
  ProjectPrimaryKeyId,
  DefectPrimaryKeyId,
  PrimaryKeyId,
  PhotoState,
  DefectLogAction,
} from "../models";

import { environment } from "../../environments/environment";

/** Defect management service */
@Injectable({
  providedIn: "root",
})
export class DefectService {
  /** @ignore */
  constructor(
    private us: UserService,
    private api: ApiService,
    private ic: ImageCompressorService,
    private analytics: AnalyticsService
  ) {}
  /**
   *
   * @param defectCloudId
   * @param defect
   * @param projectCloudId
   * @param cloudobject
   * @returns
   */
  async getDefect(
    defectId: DefectPrimaryKeyId,
    projectId: ProjectPrimaryKeyId
  ): Promise<{
    defect: Defect.Defect;
    photos: Defect.Photo[];
    contractors: IDefectContractorAssignment[];
    comments: IComment[];
  }> {
    if (!environment.production) {
      console.log("getCloudDefect()");
    }

    let defect: Defect.Defect,
      contractors: IDefectContractorAssignment[] = [],
      photos: Defect.Photo[] = [],
      comments: IComment[] = [];

    if (this.us.premium === undefined) {
      await this.us.awaitPremium();
    }

    if (this.us.premium) {
      const result = await this.api.defects.getDefect(defectId, projectId);

      if (result.success) {
        defect = {
          ...result.data,
          cloudId: result.data.id,
          priority:
            result.data.priority === undefined ? 1 : result.data.priority,
          created:
            result.data.createDate !== ""
              ? new Date(result.data.createDate)
              : new Date(),
          completed:
            result.data.completed !== ""
              ? new Date(result.data.completed)
              : undefined,
          dueDate:
            result.data.dueDate !== ""
              ? new Date(result.data.dueDate)
              : undefined,
        };

        photos = [
          ...result.data.photo.map<Defect.Photo>((o) => {
            return {
              cloudId: o.cloudId,
              defectId: o.defectId,
              filename: o.filename,
              fullPath: o.fullPath,
              thumbnail: o.thumbnail,
              createdBy: o.createdBy,
              created: o.createDate ? new Date(o.createDate) : new Date(),
            };
          }),
        ];

        contractors = result.data.contractor.map((o) => {
          return {
            ...o,
            cloudId: o.cloudId,
            contractorCloudId: o.contractorCloudId,
            projectMapId: null,
            defectMapId: null,
            defectId: null,
            selected: true,
          };
        });

        try {
          comments = result.data.activity.map<IComment>((o: any) => {
            return {
              cloudId: o.cloudId,
              comment: o.comment,
              userId: o.userId,
              username: o.username,
              created: o.created,
              lastModified: o.lastModified,
              photos: o.photos.map((p: any) => {
                const photo: ICommentPhoto = {
                  state: PhotoState.current,
                  ...p,
                };
                return photo;
              }),
            };
          });
          comments.sort((a, b) => b.created - a.created);
        } catch (ex) {
          console.error(ex);
        }

        return { defect, contractors, photos, comments };
      } else {
        return Promise.reject(result.message);
      }
    }

    return Promise.reject("Permission denied");
  }
  /**
   *
   * @param defectCloudId
   * @param defect
   * @param projectCloudId
   * @param cloudobject
   * @returns
   */
  async getClientDefect(
    defectId: DefectPrimaryKeyId,
    projectId: ProjectPrimaryKeyId
  ): Promise<{
    defect: Defect.Defect;
    photos: Defect.Photo[];
  }> {
    if (!environment.production) {
      console.log("getClientDefect()");
    }

    let defect: Defect.Defect,
      photos: Defect.Photo[] = [];

    const result = await this.api.client.getDefect(defectId, projectId);

    if (result.success) {
      defect = {
        ...result.data,
        cloudId: result.data.id,
        created:
          result.data.createDate !== ""
            ? new Date(result.data.createDate)
            : new Date(),
        completed:
          result.data.completed !== ""
            ? new Date(result.data.completed)
            : undefined,
        dueDate:
          result.data.dueDate !== ""
            ? new Date(result.data.dueDate)
            : undefined,
      };

      photos = [
        ...result.data.photo.map<Defect.Photo>((o) => {
          return {
            cloudId: o.cloudId,
            defectId: o.defectId,
            filename: o.filename,
            fullPath: o.fullPath,
            thumbnail: o.thumbnail,
            createdBy: o.createdBy,
            created: o.createDate ? new Date(o.createDate) : new Date(),
          };
        }),
      ];

      return { defect, photos };
    } else {
      return Promise.reject(result.message);
    }
  }
  /**
   * Save the defect
   * - Update existing, or
   * - Create new
   */
  async create(
    defect: Defect.Defect,
    defectContractorIds: PrimaryKeyId[],
    defectPhotos: Defect.Photo[],
    projectId: ProjectPrimaryKeyId,
    listId: ListPrimaryKeyId,
    isClientPortal?: boolean
  ): Promise<{
    success: boolean;
    updateData: IProjectProgressSummaryUpdate | undefined;
    defect: Defect.Defect;
    updatedDefectPhotos: Defect.Photo[];
    photosModifiedOnServer: boolean;
  }> {
    let updatedDefectPhotos: Defect.Photo[] = [];

    if (!environment.production) {
      console.log("create()");
    }
    try {
      const photos: ICreateDefectPhoto[] = [];

      for (let i = 0; i < defectPhotos.length; i++) {
        if (defectPhotos[i].fullPath !== "") {
          try {
            const base64Photo: string = await this.ic.limitImageSize(
              defectPhotos[i].fullPath,
              this.ic.maximumImageUploadDimension
            );

            photos.push({
              cloudId: -1,
              filename: defectPhotos[i].filename,
              base64Photo: base64Photo,
            });
          } catch (ex) {
            // unable to convert
          }
        }
      }

      // Save to cloud, get cloudId back
      const result = await this.createCloudDefect(
        defect,
        defectContractorIds,
        photos,
        listId,
        projectId,
        isClientPortal === true
      );

      if (result.success) {
        defect = result.defect;
        defect.cloudId = result.defect.cloudId;

        if (!isClientPortal) {
          this.incrementDefectCount().then((newCount: number) => {
            this.us.defectCount = newCount;
          });
        }
      }

      if (!isClientPortal) {
        this.analytics.trackCreateDefect({
          hasAssignment: defectContractorIds.length > 0,
        });
      }

      // update project page data
      const updateData: IProjectProgressSummaryUpdate = {
        listId: listId,
        defectId: defect.cloudId,
        newStatus: defect.status,
        oldStatus: null,
      };

      return {
        success: true,
        updateData,
        photosModifiedOnServer: true,
        defect,
        updatedDefectPhotos,
      };
    } catch (ex) {
      return Promise.reject(ex);
    }
  }
  /**
   * Save the defect
   * - Update existing, or
   * - Create new
   */
  async edit(
    defect: Defect.Defect,
    defectPhotos: Defect.Photo[],
    originalDefectPhotos: Defect.Photo[],
    defectContractorIds: PrimaryKeyId[],
    originalDefectContractors: IDefectContractorAssignment[],
    projectId: ProjectPrimaryKeyId,
    listId: ListPrimaryKeyId,
    oldDefectStatus: number | undefined,
    isClientPortal?: boolean
  ): Promise<{
    success: boolean;
    updateData: IProjectProgressSummaryUpdate | undefined;
    photosModifiedOnServer: boolean;
    defect: Defect.Defect;
    updatedDefectPhotos: Defect.Photo[];
  }> {
    if (!environment.production) {
      console.log("update()");
    }
    let editPhotos: IEditDefectPhoto[] = [],
      editContractors: IEditDefectContractor[] = [],
      updatedDefectPhotos: Defect.Photo[] = [];

    try {
      const photos = [...defectPhotos];
      // Determine which files are new - not an original
      const photoToAdds: Defect.Photo[] = photos.reduce(
        (result: Defect.Photo[], p) => {
          // Found unsynced photo - add it
          const index = originalDefectPhotos.findIndex((op) => {
            return p.cloudId === op.cloudId;
          });
          if (p.cloudId === -1 || index < 0) {
            result.push(p);
          }
          return result;
        },
        []
      );

      // Convert fullPath dataurl to fixed size base64 attribute
      let photoAdds: IEditDefectPhoto[] = [];
      for (let i = 0; i < photoToAdds.length; i++) {
        if (photoToAdds[i].fullPath !== "") {
          try {
            const base64: string = await this.ic.limitImageSize(
              photoToAdds[i].fullPath,
              this.ic.maximumImageUploadDimension
            );
            photoAdds.push({
              cloudId: -1,
              filename: photoToAdds[i].filename,
              base64Photo: base64,
              action: "add",
            });
          } catch (ex) {
            // unable to convert
          }
        }
      }

      // Determine which original files are not in new array
      const photoRemoves: IEditDefectPhoto[] = originalDefectPhotos.reduce(
        (result: IEditDefectPhoto[], op: Defect.Photo) => {
          const index = photos.findIndex((p) => {
            return p.cloudId === op.cloudId;
          });
          if (op.cloudId !== -1 && index < 0) {
            result.push({
              cloudId: op.cloudId,
              filename: op.filename,
              base64Photo: "",
              action: "remove",
            });
          }
          return result;
        },
        []
      );

      editPhotos = [...photoAdds, ...photoRemoves];

      // console.log("PHOTO EDITS", editPhotos);
    } catch (ex) {
      // error manipulating photo data
    }

    try {
      const contractorAdds: IEditDefectContractor[] =
        defectContractorIds.reduce(
          (result: IEditDefectContractor[], contractorId) => {
            const index = originalDefectContractors.findIndex((oc) => {
              return contractorId === oc.cloudId;
            });
            if (index < 0) {
              result.push({
                contractorId: contractorId,
                action: "add",
              });
            }
            return result;
          },
          []
        );

      const contractorRemoves: IEditDefectContractor[] =
        originalDefectContractors.reduce(
          (result: IEditDefectContractor[], oc) => {
            const index = defectContractorIds.findIndex((contractorId) => {
              return contractorId === oc.cloudId;
            });

            if (index < 0) {
              result.push({
                contractorId: oc.contractorCloudId,
                action: "remove",
              });
            }
            return result;
          },
          []
        );

      editContractors = [...contractorAdds, ...contractorRemoves];
    } catch (ex) {
      // error manipulating contractor data
    }

    try {
      // FIXME: DMS-1024 edit failed and defect was undefined(?) should have thrown exception(?)
      const cloudEditResult: {
        success: boolean;
        defect: Defect.Defect;
        cloudPhotos: IHttpEditDefectPhotoIds[];
      } = await this.editCloudDefect(
        {
          defect,
          editContractors,
          editPhotos,
        },
        projectId,
        isClientPortal === true
      );

      if (cloudEditResult.success) {
        this.analytics.trackCustomEvent(ANALYTICS_EVENTS.defectEdited);

        updatedDefectPhotos = [...defectPhotos];

        const updateData: IProjectProgressSummaryUpdate = {
          listId: listId,
          defectId: defect.cloudId,
          oldStatus: oldDefectStatus ?? null,
          newStatus: cloudEditResult.defect.status,
        };

        return {
          success: true,
          updateData,
          photosModifiedOnServer: editPhotos.length > 0,
          updatedDefectPhotos,
          defect,
        };
      }
    } catch (ex) {
      return Promise.reject(ex);
    }

    return {
      success: false,
      updateData: undefined,
      photosModifiedOnServer: false,
      updatedDefectPhotos,
      defect,
    };
  }
  /**
   *
   * @param defect
   * @param contractorIds
   * @param photos
   * @param siteId
   * @param projectId
   * @returns
   */
  async createCloudDefect(
    defect: Defect.Defect,
    contractorIds: PrimaryKeyId[],
    photos: ICreateDefectPhoto[],
    siteId: ListPrimaryKeyId,
    projectId: ProjectPrimaryKeyId,
    isClientPortal: boolean
  ): Promise<{
    success: boolean;
    defect: Defect.Defect;
    cloudPhotos: IHttpEditDefectPhotoIds[];
    dcmMaps: IHttpEditDefectDcmIds[];
  }> {
    let success: boolean = false,
      cloudPhotos: IHttpEditDefectPhotoIds[] = [],
      dcmMaps: IHttpEditDefectDcmIds[] = [];

    const createDefect: ICreateDefect = {
      siteId: siteId,
      area: defect.area,
      element: defect.element,
      issue: defect.issue,
      comments: defect.comments,
      status: defect.status,
      priority: defect.priority,
      dueDate: defect.dueDate?.toISOString() ?? "",
    };

    const httpDefect = isClientPortal
      ? await this.api.client.createDefect(
          createDefect,
          contractorIds,
          photos,
          projectId
        )
      : await this.api.defects.createDefect(
          createDefect,
          contractorIds,
          photos,
          projectId
        );

    success = httpDefect.success;

    if (httpDefect.success) {
      const { data } = httpDefect;
      defect.cloudId = data.cloudId;
      cloudPhotos = data.photoIds;
      dcmMaps = data.dcmIds;
      defect.defectNumber = data.defectNumber;
    }

    return { success, defect, cloudPhotos, dcmMaps };
  }
  /**
   * Save changes to server
   * @param {IApiEditDefect} editDefectData defect to update on server
   * @param {number} projectId
   * @returns {number | undefined} lastModified
   */
  async editCloudDefect(
    editDefectData: IApiEditDefect,
    projectId: ProjectPrimaryKeyId,
    isClientPortal: boolean
  ): Promise<{
    success: boolean;
    defect: Defect.Defect;
    cloudPhotos: IHttpEditDefectPhotoIds[];
    dcmMaps: IHttpEditDefectDcmIds[];
  }> {
    let lastModified: number | undefined,
      cloudPhotos: any[] = [],
      dcmMaps: any[] = [];

    const { defect, editContractors, editPhotos } = editDefectData;

    if (this.us.premium === undefined) {
      await this.us.awaitPremium();
    }

    if (this.us.premium) {
      const _defect: IEditDefect = {
        id: defect.cloudId,
        area: defect.area,
        element: defect.element,
        issue: defect.issue,
        comments: defect.comments,
        status: defect.status,
        priority: defect.priority,
        created: defect.created.toISOString(),
        completed: defect.completed?.toISOString() ?? "",
        dueDate: defect.dueDate?.toISOString() ?? "",
      };
      const httpSite = isClientPortal
        ? await this.api.client.editDefect(
            _defect,
            editContractors,
            editPhotos,
            projectId
          )
        : await this.api.defects.editDefect(
            _defect,
            editContractors,
            editPhotos,
            projectId
          );
      if (httpSite.success) {
        lastModified = httpSite.data.projectLastModified;
        cloudPhotos = httpSite.data.photoIds;
        dcmMaps = httpSite.data.dcmIds;
      }
    }

    return Promise.resolve({
      success: lastModified !== undefined,
      defect,
      cloudPhotos,
      dcmMaps,
    });
  }
  /**
   * Increment the tenant defect count and local value
   * @returns {number} newCount
   * @throws {undefined} error
   */
  async incrementDefectCount(): Promise<number> {
    try {
      const result = await this.api.defects.setDefectCount();
      if (result.success) {
        if (!environment.production) {
          console.log("New Count: ", result.data.newCount);
        }
        return Promise.resolve(result.data.newCount);
      } else {
        return Promise.reject(result.message);
      }
    } catch (ex) {
      return Promise.reject(ex);
    }
  }
  /**
   * Delete a single entry from the server
   * @param cloudDefectId
   * @param clouddefect
   * @param projectCloudId
   * @returns true/false
   */
  async deleteCloudDefect(
    defectId: DefectPrimaryKeyId,
    projectId: ProjectPrimaryKeyId
  ): Promise<{ success: boolean }> {
    let success: boolean = false;

    if (this.us.premium === undefined) {
      await this.us.awaitPremium();
    }

    if (this.us.premium) {
      try {
        const httpResult = await this.api.defects.deleteDefect(
          defectId,
          projectId
        );
        success = httpResult.success;
      } catch (ex) {
        return Promise.reject(`Unable to delete from server. ${ex}`);
      }
    }

    return Promise.resolve({ success });
  }

  /**
   * Loads more comments for a given defect ID.
   *
   * @param {DefectPrimaryKeyId} defectId - The ID of the defect to load comments for.
   * @return {Promise<IComment[]>} - A promise that resolves to an array of comments.
   */
  async loadMoreComments(defectId: DefectPrimaryKeyId): Promise<IComment[]> {
    let comments: IComment[] = [];

    try {
      const result = await this.api.comments.get(defectId);

      if (result.success) {
        comments = result.data.map<IComment>((o: any) => {
          return {
            cloudId: o.cloudId,
            comment: o.comment,
            userId: o.userId,
            username: o.username,
            created: o.created,
            lastModified: o.lastModified,
            photos: o.photos.map((p: any) => {
              const photo: ICommentPhoto = {
                state: PhotoState.current,
                ...p,
              };
              return photo;
            }),
          };
        });
        comments.sort((a, b) => b.created - a.created);
      } else {
        return Promise.reject(result.message);
      }
    } catch (ex) {
      Promise.reject(ex);
    }

    return comments;
  }

  /**
   * Load Defect History
   * @param defectId
   * @returns
   */
  async getDefectHistory(defectId: DefectPrimaryKeyId): Promise<DefectLog> {
    let logResult: DefectLog = [];
    try {
      const result = await this.api.defects.getDefectHistory(defectId);
      if (result.success) {
        const parsedLogs = result.data.map<DefectLogItem>((o) => {
          const fieldNameLowerCase = o.fieldName.toLowerCase();
          assertFieldName(fieldNameLowerCase);
          const parsedDate = new Date(Number(o.actionTimestamp));

          let actionType = DefectLogAction.unknown;
          switch (o.actionType.toLowerCase()) {
            case DefectLogAction.created:
              actionType = DefectLogAction.created;
              break;
            case DefectLogAction.synced:
              actionType = DefectLogAction.synced;
              break;
            case DefectLogAction.updated:
            default:
              actionType = DefectLogAction.updated;
              break;
          }

          return {
            id: o.id,
            defectId: o.defectId,
            userId: o.userId,
            actionTimestamp: parsedDate,
            actionType: actionType,
            fieldName: fieldNameLowerCase,
            from: o.from,
            to: o.to,
            userName: o.userName,
          };
        });

        // get unique dates
        const timestamps = new Set<number>();
        for (let i = 0; i < parsedLogs.length; i++) {
          timestamps.add(parsedLogs[i].actionTimestamp.getTime());
        }

        // create result array
        for (const timestamp of timestamps) {
          const items = [...parsedLogs].filter(
            (o) => o.actionTimestamp.getTime() === timestamp
          );
          logResult.push({
            timestamp: new Date(timestamp),
            userName: items.length > 0 ? items[0].userName : "",
            actionType:
              items.length > 0 ? items[0].actionType : DefectLogAction.updated,
            items: items,
          });
        }

        // sort logResult by timestamp new to old
        logResult.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
      } else {
        return Promise.reject(result.message);
      }
    } catch (ex) {
      Promise.reject(ex);
    }
    return logResult;
  }
}

/**
 * Mostly place holding to avoid type errors above, not really used ATM.
 * @param value value to test
 */
function assertFieldName(value: any): asserts value is HistoryLogFieldName {
  if (typeof value !== "string")
    throw new Error("HistoryLogFieldName id not available");
}

import { Injectable } from "@angular/core";
import { AlertService } from "./alert.service";

import { LogService } from "./log.service";
import { environment } from "src/environments/environment";

/**
 * Handle catch exceptions from try catches
 */
@Injectable({
  providedIn: "root",
})
export class CatchErrorService {
  /** @ignore */
  constructor(private as: AlertService, private log: LogService) {}

  /**
   * Handle an exception thrown by try catch
   * i) Rollbar, ii) Save local if offline, iii) Show optional alert
   * @param {any} exception (ex)/(err) thrown by catch
   * @param {boolean} showAlert Defaults to `false`. Set `true` to show alert dialogue
   * @param {string} message Optional Alert & Console.Log() prefix to show before exception.message
   * @returns {void}
   */
  async handle(
    exception: any,
    showAlert?: boolean,
    message?: string
  ): Promise<void> {
    if (message && !environment.production) {
      console.error(message, exception);
    } else if (!environment.production) {
      console.error("Exception occurred", exception);
    }

    await this.log.exception(exception);

    if (showAlert) {
      let msg = "";
      if (message) msg = message + " ";

      if (exception) {
        if (typeof exception === "string") {
          msg += exception;
        } else if (exception.message) {
          msg += exception.message;
        }
      }
      await this.as.showAlert("Error", "", msg);
    }

    return Promise.resolve();
  }

  /**
   * Log as an info to rollbar only
   * @param message
   */
  async logMessage(message: any): Promise<void> {
    await this.log.info(message);
  }

  /**
   * Log as an warning to rollbar only
   * @param message
   */
  async logWarning(message: any): Promise<void> {
    await this.log.warning(message);
  }

  /**
   * Bit of a hacky shortcut to the LOG service
   * @param message 
   * @param data Generic Object
   */
  breadcrumb(message: string, data: any): void {
    this.log.breadcrumb(message, data);
  }
}

import { DynamicDialogConfig } from "primeng/dynamicdialog";

/** Default width values for Prime Dialog popups */
export const defaultDialogConfig: DynamicDialogConfig = {
  width: "90vw",
  style: { minWidth: "400px", width: "90vw", maxWidth: "600px" },
};

export const links = {
  /**
   * Link to support portal
   *
   * - `"https://www.defectwise.com.au/support"` - change as required
   *
   * - No Params
   */
  support: "https://www.defectwise.com.au/support",
  /**
   * Link to DefectWise documentation
   *
   * - No Params
   */
  documentation: "https://www.defectwise.com.au/docs",
  /**
   * Link to DefectWise pricing page
   *
   * - No Params
   */
  pricing: "https://www.defectwise.com.au/pricing",
  /**
   * Link to apple store
   *
   * - params `?pt=118098733&ct=webapp&mt=8&`
   */
  urlAppleDownload:
    "https://apps.apple.com/app/apple-store/id1514729575?pt=118098733&ct=webapp&mt=8&",
  /**
   * Link to google play
   *
   * - params
   * - `utm_source=webapp`
   * - `utm_medium=link`
   * - `utm_content=home_btn`
   * - `utm_campaign=webapp`
   */
  urlGoogleDownload:
    "https://play.google.com/store/apps/details?id=com.interapptive.defectwise&referrer=utm_source%3Dwebapp%26utm_medium%3Dlink%26utm_content%3Dhome_btn%26utm_campaign%3Dwebapp",
};

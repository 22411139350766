import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { environment } from "src/environments/environment";
// import { interval } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ServiceWorkerUpdateService {
  constructor(public updates: SwUpdate) {
    // if (updates.isEnabled) {
    //   interval(6 * 60 * 60).subscribe(() =>
    //     updates.checkForUpdate().then(() => console.log("checking for updates"))
    //   );
    // }
  }

  /**
   * Waits 5 seconds then checks for updates
   * @returns
   */
  async checkForUpdates(): Promise<void> {
    if (!environment.production) return;

    setTimeout(async () => {
      try {
        // TODO: DMS-1453 updates is undefined
        const res = await this.updates.checkForUpdate();
        if (res) {
          this.promptUser();
        }
      } catch (ex) {
        console.error(ex);
      }
    }, 5000);
  }

  private promptUser(): void {
    if (!environment.production) return;

    try {
      console.log("updating to new version");
      this.updates.activateUpdate().then((updated: boolean) => {
        if (updated) document.location.reload();
      });
    } catch (ex) {
      console.error(ex);
    }
  }
}

import { Injectable } from "@angular/core";

import { UserService } from "./user.service";
import { ApiService } from "./api/index";
import { CatchErrorService } from "./catch.service";

import {
  Project,
  List,
  IEditSite,
  ICreateSite,
  IDefectStatus,
  ProjectPrimaryKeyId,
  ListPrimaryKeyId,
  ClientListResponse,
  ClientSelection,
} from "../models";

/**
 * Site management service
 */
@Injectable({
  providedIn: "root",
})
export class SiteService {
  /** @ignore */
  constructor(
    private cx: CatchErrorService,
    private us: UserService,
    private api: ApiService
  ) {}

  /**
   * Get page data from local and/or server
   * @param {number} listId is either local site.id or cloudId
   * @param {number} projectId used for local query only (?)
   * @returns
   *
   * @since 1.3.0
   */
  async getSitePageData(
    listId: ListPrimaryKeyId,
    projectId: ProjectPrimaryKeyId
  ): Promise<{
    site: List.List;
    defects: List.DefectView[];
    clients: ClientSelection[];
  }> {
    let site: List.List,
      defects: List.DefectView[] = [],
      clients: ClientSelection[] = [];

    try {
      const result = await this.api.sites.getSite(listId, projectId);
      if (result.success) {
        site = {
          ...result.data.site,
          cloudId: result.data.site.id,
          projectCloudId: projectId,
          projectAddress: "",
          unit: result.data.site.description,
          address: result.data.site.address,
          floor: result.data.site.floor,
          type: result.data.site.type,
          lastModified: result.data.site.lastModified ?? 0,
        };
        defects = result.data.defects.map<List.DefectView>((cd: any) => {
          return {
            ...cd,
            id: null,
            photo: cd.fullSizePath,
            thumbnail: cd.photo,
            cloudId: cd.id,
            imageErrorCount: 0,
            isSynced: 0,
            hasComments: cd.hasComments > 0,
            priority: cd.priority !== undefined ? cd.priority : 1,
          };
        });
        clients = result.data.clients.map((o) => {
          return { ...o, valid: true };
        });
      }
    } catch (ex) {
      return Promise.reject(`Unable to load list. ${ex}`);
    }

    return { site: site!, defects, clients };
  }

  /**
   * Get page data from local and/or server
   * @param {number} listId is either local site.id or cloudId
   * @param {number} projectId used for local query only (?)
   * @returns
   *
   * @since 1.3.0
   */
  async getClientSitePageData(
    listId: ListPrimaryKeyId,
    projectId: ProjectPrimaryKeyId
  ): Promise<{
    site: List.List;
    defects: List.DefectView[];
  }> {
    let site: List.List,
      defects: List.DefectView[] = [];

    try {
      const result = await this.api.client.getList(listId, projectId);
      if (result.success) {
        site = {
          ...result.data.site,
          cloudId: result.data.site.id,
          projectCloudId: projectId,
          projectAddress: "",
          unit: result.data.site.description,
          address: result.data.site.address,
          floor: result.data.site.floor,
          notes: result.data.site.notes,
          type: result.data.site.type,
          lastModified: result.data.site.lastModified ?? 0,
        };
        defects = result.data.defects.map<List.DefectView>((cd: any) => {
          return {
            ...cd,
            id: null,
            photo: cd.fullSizePath,
            thumbnail: cd.photo,
            cloudId: cd.id,
            imageErrorCount: 0,
            isSynced: 0,
            hasComments: cd.hasComments > 0,
          };
        });
      }
    } catch (ex) {
      return Promise.reject(`Unable to load list. ${ex}`);
    }

    return { site: site!, defects };
  }

  /**
   * Generate a site record on the server
   * @param site returns modified input with cloudId if saved to db
   * @param projectCloudId either server date or `new Date().getTime()`
   * @param cloudobject
   *
   * @returns modified site if cloud is available + lastmodified date if successfully updated otherwise undefined
   */
  async createCloudSite(
    site: List.List,
    projectCloudId: ProjectPrimaryKeyId
  ): Promise<{ site: List.List; lastModified: number | undefined }> {
    let lastModified: number | undefined = undefined;

    if (this.us.premium === undefined) {
      await this.us.awaitPremium();
    }

    if (this.us.premium) {
      const _site: ICreateSite = {
        projectId: projectCloudId,
        unit: site.unit,
        floor: site.floor,
        address: site.address,
      };

      const httpSite = await this.api.sites.createSite(_site, projectCloudId);

      if (httpSite.success) {
        site.cloudId = httpSite.data.cloudId as ListPrimaryKeyId;
        lastModified = httpSite.data.projectLastModified;
      }
    }

    return Promise.resolve({ site, lastModified });
  }

  /**
   * Generate a site record on the server
   * @param site returns modified input with cloudId if saved to db
   * @param projectCloudId
   * @param cloudobject
   * @returns lastModified datetime if successful otherwise undefined
   */
  async editCloudSite(
    site: List.List,
    projectCloudId: ProjectPrimaryKeyId
  ): Promise<{ lastModified: number | undefined }> {
    let lastModified: number | undefined = undefined;

    if (this.us.premium === undefined) {
      await this.us.awaitPremium();
    }

    if (this.us.premium) {
      const _site: IEditSite = {
        unit: site.unit,
        floor: site.floor,
        address: site.address,
        notes: site.notes,
        id: site.cloudId,
      };

      const httpSite = await this.api.sites.editSite(_site, projectCloudId);

      if (httpSite.success) {
        lastModified = httpSite.data.projectLastModified;
      }
    }

    return Promise.resolve({ lastModified });
  }

  /**
   * Delete site from server
   * @param cloudId id of site
   * @param projectCloudId
   * @param cloudsite
   * @returns
   */
  async deleteCloudSite(
    cloudId: ListPrimaryKeyId,
    projectCloudId: ProjectPrimaryKeyId
  ): Promise<{
    success: boolean;
    message: string;
    lastModified: number | undefined;
  }> {
    let success: boolean = false,
      message: string = "Auto sync disabled",
      modified: number | undefined;

    if (cloudId > 0) {
      if (this.us.premium === undefined) {
        await this.us.awaitPremium();
      }

      if (this.us.premium) {
        const httpResult = await this.api.sites.deleteSite(
          cloudId,
          projectCloudId
        );

        success = httpResult.success;
        message = httpResult.message;

        if (httpResult.success) {
          modified = httpResult.data.projectLastModified;
        }
      }
    }

    return Promise.resolve({
      success,
      message,
      lastModified: modified,
    });
  }

  /**
   * Clones an existing list and its defects
   * @param cloudId id of site
   * @param projectCloudId
   * @param cloudsite
   * @returns
   */
  async cloneCloudList(listdId: ListPrimaryKeyId): Promise<{
    success: boolean;
    message: string;
    listId: number | undefined;
  }> {
    let success: boolean = false,
      message: string = "Unknown error",
      listId: number | undefined;

    if (listdId > 0) {
      if (this.us.premium === undefined) {
        await this.us.awaitPremium();
      }

      if (this.us.premium) {
        const httpResult = await this.api.sites.cloneList(listdId);

        success = httpResult.success;
        message = httpResult.message;

        if (httpResult.success) {
          listId = httpResult.data.listCloudId;
        }
      }
    }

    return Promise.resolve({
      success,
      message,
      listId: listId,
    });
  }

  /**
   * Update the DEFECTS table Status and Modified columns
   * @param defect defect to update (only requrie Id)
   * @param status the int status 0, 1, 2
   * @param cloudsite
   * @param syncStatus
   * @param projectCloudId
   * @returns {boolean}
   */
  async setDefectStatus(
    defect: List.DefectView,
    status: number | IDefectStatus,
    projectCloudId: ProjectPrimaryKeyId
  ): Promise<{ serverChanged: boolean }> {
    let serverChanged: boolean = false;

    if (status === IDefectStatus.COMPLETE && defect.completed === null) {
      defect.completed = new Date();
    }

    if (defect.cloudId > 0) {
      if (this.us.premium === undefined) {
        await this.us.awaitPremium();
      }

      if (this.us.premium) {
        const httpSite = await this.api.defects.setDefectStatus(
          {
            defectId: defect.cloudId,
            status,
            completed: defect.completed?.toISOString() || "",
          },
          projectCloudId
        );

        serverChanged = httpSite.success;
      }
    }

    return Promise.resolve({ serverChanged });
  }

  /**
   * EVERYTHING BELOW THIS LINE HAS NOT BEEN ADDED TO FLUTTER
   */

  /**
   *
   * @param site
   * @param defects
   * @param cloudsite
   * @param syncStatus
   * @returns
   */
  async moveDefects(
    site: Project.ListView,
    defects: List.DefectView[]
  ): Promise<{ serverChanged: boolean }> {
    // console.log("moveDefects()");

    let serverChanged: boolean = false;

    const cloudIds = defects.filter((o) => {
      return o.cloudId != null && o.cloudId > -1;
    });

    if (cloudIds.length == 0) {
      return { serverChanged };
    }

    if (this.us.premium) {
      try {
        const httpSite = await this.api.defects.moveDefects(
          site.cloudId,
          cloudIds.map((o) => {
            return o.cloudId;
          })
        );
        serverChanged = httpSite.success;
      } catch (ex) {
        this.cx.handle(ex, false);
        return Promise.reject(ex);
      }
    }
    return { serverChanged };
  }

  async setClientList(
    listId: ListPrimaryKeyId,
    adds: string[],
    removes: number[]
  ): Promise<{
    success: boolean;
    message: string;
    data: ClientListResponse[];
  }> {
    try {
      const httpSite = await this.api.sites.clientList(listId, adds, removes);

      return Promise.resolve({
        ...httpSite,
      });
    } catch (ex) {
      this.cx.handle(ex, true);
      return Promise.reject(ex);
    }
  }
}

import { Injectable } from "@angular/core";

import { ApiService } from "./api/index";

import type {
  IBillingCreateCustomer,
  IBillingPortal,
  IBillingSession,
  StripeCustomerId,
} from "../models";

@Injectable({
  providedIn: "root",
})
export class BillingService {
  productId: string | undefined;
  quantity: number = 1;
  customerId: string | undefined;
  redirectToBilling: boolean = false;

  constructor(private api: ApiService) {}

  /**
   * Register a new user
   * @returns {any} Stripe Customer Object
   * @throws exception or error message
   */
  async createCustomer(
    email: string,
    name: string,
    phone: string
  ): Promise<IBillingCreateCustomer> {
    try {
      const result = await this.api.billing.createCustomer(email, name, phone);
      if (result.success) {
        return Promise.resolve(result.data);
      } else {
        return Promise.reject(result.message);
      }
    } catch (ex) {
      return Promise.reject(ex);
    }
  }

  /**
   * Get subscription from stripe api
   * @param {string} customerId Stripe Customer Id
   * @returns {string} subscription id, undefined if not found
   * @throws exception or error message
   */
  async getSubscription(
    customerId: StripeCustomerId
  ): Promise<StripeCustomerId> {
    try {
      const result = await this.api.billing.getSubscription(customerId);
      if (result.success) {
        return Promise.resolve(result.data);
      } else {
        return Promise.reject(result.message);
      }
    } catch (ex) {
      return Promise.reject(ex);
    }
  }

  /**
   * Get subscription from stripe api
   * https://stripe.com/docs/billing/subscriptions/integrating-customer-portal#redirect
   * @param {string} customerId Stripe Customer Id
   * @returns {any} Portal
   */
  async getPortal(customerId: StripeCustomerId): Promise<IBillingPortal> {
    try {
      const result = await this.api.billing.getPortal(customerId);
      if (result.success) {
        return Promise.resolve(result.data);
      } else {
        return Promise.reject(result.message);
      }
    } catch (ex) {
      return Promise.reject(ex);
    }
  }

  /**
   * Get sesssion from stripe api
   * @param {string} customerId Stripe Customer Id
   * @param {string} productId Stripe Product Id
   * @param {number} quantity number of sub's
   * @param {string} email Customer email
   * @param {string} promoId Stripe promo id
   *
   * @returns {any} Stripe Session
   */
  async getSession(
    customerId: StripeCustomerId,
    productId: string,
    quantity: number,
    email: string,
    promoId?: string
  ): Promise<IBillingSession> {
    try {
      const result = await this.api.billing.getSession(
        customerId,
        productId,
        quantity,
        email,
        promoId
      );
      if (result.success) {
        return Promise.resolve(result.data);
      } else {
        return Promise.reject(result.message);
      }
    } catch (ex) {
      return Promise.reject(ex);
    }
  }
}

import { DefectPrimaryKeyId } from "./brands.model";

/** Defect Page */
export enum IDefectStatus {
  NEW = 0,
  IN_PROGRESS = 1,
  COMPLETE = 2,
}

/** Defect Page */
export enum IDefectPriority {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
  CRITICAL = 3,
}

export namespace Defect {
  /** Defect interface */
  export type Defect = {
    cloudId: DefectPrimaryKeyId;
    defectNumber: number;
    area: string;
    element: string;
    issue: string;
    comments: string;
    created: Date;
    completed: Date | undefined;
    dueDate: Date | undefined;
    status: IDefectStatus | number;
    priority: IDefectStatus | number;
    readonly defectNumberString: string;
    readonly lastModified: number;
  };

  /**
   * @since web v2
   */
  export type Photo = {
    /** Photo id  */
    cloudId: number;
    /** Photo Defect Id */
    defectId: number;
    /** Photo safe view URL - optional */
    fullPath: string;
    /** Photo safe view URL - optional */
    thumbnail: string;
    /** filename */
    filename: string;
    /** */
    createdBy: string;
    /** */
    created: Date;
  };
}

import { Injectable } from "@angular/core";
import { FilterMetadata } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class PrimeTableHelperService {
  /** @ignore */
  constructor() {}
  /**
   * Retrieves the value from local storage based on the provided key.
   *
   * @param {string} key - The key to identify the value in local storage.
   * @return {any | undefined} The value retrieved from local storage or undefined if not found.
   */
  get(key: string): any | undefined {
    const json = window.localStorage.getItem(key);
    try {
      if (json) {
        let data = JSON.parse(json);
        return data;
      }
    } catch (ex) {
      console.error(ex);
    }
    return;
  }
  /**
   * Sets the value in the local storage based on the provided key.
   *
   * @param {string} tableLocalStoreKey - The key to identify the value in local storage.
   * @param {any} data - The data to be stored in local storage.
   */
  set(tableLocalStoreKey: string, data: any): void {
    window.localStorage.setItem(tableLocalStoreKey, JSON.stringify(data));
  }

  /**
   * Accepts the table filter data and checks if a filter is applied
   * @param event
   */
  tableOnFilter(event: {
    filteredValue: any[];
    filters: { [s: string]: FilterMetadata | FilterMetadata[] | undefined };
  }): boolean | undefined {
    function _checkFilterValue(
      value: string | number | null | FilterMetadata[]
    ): boolean {
      let hasValue = false;
      if (Array.isArray(value)) {
        hasValue = value.length > 0;
      } else if (value || value === 0) {
        hasValue = true;
      }
      return hasValue;
    }

    if (event.filters) {
      let hasFilter = false;

      for (let key in event.filters) {
        if (hasFilter) break;

        let item: FilterMetadata | FilterMetadata[] | undefined | null =
          event.filters[key];

        if (item !== undefined && item !== null) {
          if (Array.isArray(item)) {
            if (item.length > 0) {
              hasFilter = _checkFilterValue(item[0].value);
            }
          } else if (typeof item === "object") {
            hasFilter = _checkFilterValue(item.value);
          }
        }
      }

      return hasFilter;
    }
    return undefined;
  }
}

import { Injectable } from "@angular/core";

import { HashDataId } from "../models";
import Hashids from "hashids";

@Injectable({
  providedIn: "root",
})
export class HashService {
  private readonly k = "PS8H2Z";
  /** */
  private readonly idHasher: Hashids;
  /** @ignore */
  constructor() {
    this.idHasher = new Hashids(this.k, 12);
  }
  /**
   * Decode a hashed string
   * @param value
   * @returns {T} pass in the id type to return
   */
  decode<T>(value: string): T {
    const result = this.idHasher.decode(value);
    const num = Number(result);
    return (isNaN(num) ? -1 : num) as T;
  }
  /**
   * Encode a number into a hashed string
   * @param value
   * @returns {HashDataId}
   */
  encode(value: number): HashDataId {
    return this.idHasher.encode(value) as HashDataId;
  }
}

import { Injectable } from "@angular/core";
import { AlertController, ToastController } from "@ionic/angular";
import { links } from "src/app/constants/constants";

/** Simple AlertController & ToastController Implementation */
@Injectable({
  providedIn: "root",
})
export class AlertService {
  /** @ignore */
  constructor(
    private alert: AlertController,
    private toastCtrl: ToastController
  ) {}

  /**
   *  Opens Basic OK AlertController
   * @param title Alert box heading
   * @param subHeader Alert box sub-heading
   * @param message Content of alert box
   * @param handler for OK button logic
   */
  async showAlert(
    title: string,
    subHeader: string,
    message: string,
    handler?: any
  ): Promise<void> {
    let buttons = [];
    if (handler) {
      buttons.push({
        text: "Ok",
        handler: handler,
      });
    } else {
      buttons.push("Ok");
    }
    const alert = await this.alert.create({
      header: title,
      subHeader: subHeader,
      message: message,
      buttons: buttons,
    });
    return alert.present();
  }

  /**
   *  Opens AlertController
   * @param title Confirm box heading
   * @param subHeader Confirm box sub-heading
   * @param message Content of confirm box
   * @param btnText Custom button, other button is cancel
   * @param handler for custom button
   * @param cancelHandler for cancel button
   */
  async showConfirm(
    title: string,
    subHeader: string,
    message: string,
    btnText: string,
    handler: any,
    cancelHandler?: any
  ): Promise<void> {
    let btns = [];
    if (cancelHandler) {
      btns.push(cancelHandler);
    } else {
      btns.push({
        text: "Cancel",
        role: "cancel",
        // handler: () => console.log("Cancelled confirm"),
      });
    }

    btns.push({
      text: btnText,
      handler: handler,
    });

    const confirm = await this.alert.create({
      header: title,
      subHeader: subHeader,
      message: message,
      buttons: btns,
    });
    await confirm.present();
  }

  /**
   *  Opens AlertController
   * @param title Confirm box heading
   * @param subHeader Confirm box sub-heading
   * @param message Content of confirm box
   * @param btnText Custom button, other button is cancel
   * @param handler for custom button
   * @param cancelHandler for cancel button
   */
  async showGetPremium(
    title: string,
    message: string,
    btnText: string,
    utmContentLink: string
  ): Promise<void> {
    let btns = [];

    btns.push({
      text: "Cancel",
      role: "cancel",
    });

    btns.push({
      text: btnText,
      handler: () => {
        window.open(
          `${links.pricing}?utm_source=web_app_link&utm_medium=web_app&utm_campaign=web_app&utm_content=${utmContentLink}`,
          "_blank"
        );
      },
    });

    const confirm = await this.alert.create({
      header: title,
      subHeader: "Get Premium",
      message: message,
      buttons: btns,
    });
    await confirm.present();
  }

  /**
   *  Opens Ionic Toast
   * @param message Toast message
   * @param duration Time to show in milliseconds
   * @param color Color of toast when displayed
   */
  async showToast(
    message: string,
    duration?: number,
    color?: string
  ): Promise<void> {
    const _duration = duration || 2000;
    const _color = color || "secondary";
    const toast = await this.toastCtrl.create({
      message: message,
      duration: _duration,
      color: _color,
    });
    toast.present();
  }
}

import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Platform } from "@ionic/angular";

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import {
  LogService,
  AnalyticsService,
  ServiceWorkerUpdateService,
} from "./services";

import { environment } from "../environments/environment";

import { PrimeNGConfig, Translation } from "primeng/api";
import { translations } from "../primelocale/en";

import { register } from "swiper/element/bundle";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  /** @ignore */
  constructor(
    private platform: Platform,
    private config: PrimeNGConfig,
    private log: LogService,
    private analytics: AnalyticsService,
    private sw: ServiceWorkerUpdateService
  ) {
    this.initializeApplication();
    this.analytics.init();
  }

  ngOnInit() {
    // Set the default locale
    // More languages here: https://github.com/primefaces/primelocale
    const userLang = navigator.language || "en";
    let locale: Translation = translations[userLang.toLowerCase()];
    if (locale === undefined) {
      // Default to en if no matching language is found
      locale = translations["en"];
    }
    this.config.setTranslation({
      ...locale,
    });
  }

  /** Registers the swiper module */
  ngAfterViewInit(): void {
    register();
    try {
      this.sw.checkForUpdates();
    } catch (ex) {
      console.error(ex);
    }
  }

  /** Runs everytime the app boots up */
  initializeApplication() {
    this.platform.ready().then(async () => {
      if (!environment.production) {
        console.log("initializeApp()");
      }

      initializeApp(environment.firebaseConfig);
      // Check if user is logged in and attach
      const auth = getAuth();
      auth.onAuthStateChanged((user) => {
        if (user !== null) {
          this.log.identifyUser({
            email: user.email,
            id: user.uid,
          });
          this.analytics.identifyUser(user.email ?? "");
        }
      });
    });
  }
}

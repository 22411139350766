import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";

import { Endpoint } from "../models";
import { getAuth } from "firebase/auth";
import { environment } from "../../environments/environment";
import type { IApiResponse } from "../models/http";
import { PERMISSION_CHECK_TYPE } from "../models/http";
import { HTTP_METHOD } from "./api";
import { PERMISSION_TYPE, SKIP_CHECK } from "./http-interceptor.service";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  /** @ignore */
  constructor(private client: HttpClient) {}

  /**
   * Make a HTTP request to the API
   * @param {string} method HTTP Request Type "GET" | "POST" | "PUT" | "DELETE"
   * @param {string} endpoint URL to request
   * @param {object} params Query params
   * @param {object} body Request data
   * @param {boolean} skipCheck Defaults to true
   * ```js
   *    skipCheck = true; // This will allow the API call through without checking user permission
   *    skipCheck = false; // This will check the user persmission first and block the API call if not found
   * ```
   * @returns {Promise<IApiResponse<T>>} HTTP Result data
   * @example Call this function like:
   * ```js
   *  this.http.request(
   *    "GET",
   *    "GetProject",
   *    { projectId: 123, userId: 123},
   *    null,
   *    false
   *  );
   * ```
   * The false at the end will enforce a permission check.
   * The 3rd and 4th args are for sending query params or post data
   * @since 1.4.0
   */
  async request<T>(
    method: HTTP_METHOD,
    endpoint: Endpoint,
    params: { [key: string]: any } | null,
    body: { [key: string]: any } | boolean | null,
    skipCheck?: boolean,
    permissionCheckType?: PERMISSION_CHECK_TYPE
  ): Promise<IApiResponse<T>> {
    if (!environment.production) {
      console.log("HTTP REQUEST /", endpoint);
    }
    try {
      skipCheck = skipCheck === undefined || skipCheck === true ? true : false;
      permissionCheckType =
        permissionCheckType ?? PERMISSION_CHECK_TYPE.GENERAL;

      // Set body data - must be undefined for GET
      const data: any = body && method !== HTTP_METHOD.GET ? body : undefined;
      // Set URL
      const url =
        endpoint.toLowerCase() === "createreport"
          ? environment.reportServerUrl + endpoint
          : environment.apiBaseUrl + endpoint;

      // Tidy Params
      let param: { [key: string]: any } = params ? params : {};
      const paramKeys = Object.keys(param);
      if (paramKeys.length > 0) {
        for (const key of paramKeys) {
          if (param[key] === null || param[key] === undefined) {
            delete param[key];
          } else {
            param[key] = `${param[key]}`;
          }
        }
      }

      // Create Params
      const httpParams = new HttpParams().appendAll(param);

      // Create Headers
      const token = await this.getToken();
      const httpHeaders =
        endpoint.toLowerCase() === "createreport"
          ? this.getReportHeaders(token)
          : this.getHeaders(token);

      // Create request context
      const context = new HttpContext().set(SKIP_CHECK, skipCheck);
      context.set(PERMISSION_TYPE, permissionCheckType);
      
      // Get and Post/Put/Delete options are diff, see types below
      const options: _AngularGetRequestOptions | _AngularRequestOptions | any =
        {
          context,
          headers: httpHeaders,
          params: httpParams,
          responseType: "json",
          observe: "body",
          body: data,
        };

      // Make the request
      //
      const ret = (await firstValueFrom(
        this.client.request<IApiResponse<T>>(method, url, options)
      )) as any;

      // Process HTTP Result
      if (ret !== undefined) {
        if (ret.data?.success === true) {
          return Promise.resolve(ret.data);
        } else {
          const errMsg = this.handleSuccessFalseMessage(ret);
          return Promise.reject(errMsg);
        }
      }

      return Promise.reject(
        "Network request failed with status #" +
          `${ret ? (ret as any).status : "ERR"}`
      );
    } catch (err) {
      if (err === "network-request-failed") {
        const errResponse: IApiResponse = { success: false, message: err };
        return Promise.resolve(errResponse as IApiResponse<T>);
      }
      return Promise.reject(err);
    }
  }

  /**
   * Uploads files to the specified endpoint.
   *
   * @param {Endpoint} endpoint - The endpoint to upload the files to.
   * @param {Object | boolean | null} fields - The fields to include in the upload request.
   * @param {File[]} files - The files to upload.
   * @return {Promise<IApiResponse<T>>} A promise that resolves to the API response.
   */
  async uploadFiles<T>(
    endpoint: Endpoint,
    fields: { [key: string]: any } | boolean | null,
    files: File[]
  ): Promise<IApiResponse<T>> {
    if (!environment.production) {
      console.log("HTTP REQUEST /", endpoint);
    }
    try {
      const url = environment.apiBaseUrl + endpoint;
      const token = await this.getToken();
      const httpHeaders = this.getUploadHeaders(token);
      const context = new HttpContext().set(SKIP_CHECK, true);
      const formData = new FormData();

      files.forEach((file: File) => {
        formData.append("file", file);
      });

      if (fields && typeof fields === "object") {
        Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
      }
      // Get and Post/Put/Delete options are diff, see types below
      const options: _AngularGetRequestOptions | _AngularRequestOptions | any =
        {
          reportProgress: false,
          context,
          headers: httpHeaders,
          responseType: "json",
          observe: "body",
          body: fields,
        };

      const req = new HttpRequest(HTTP_METHOD.POST, url, formData, options);

      // Make the request
      //
      const ret = (await firstValueFrom(
        this.client.request(req)
      )) as HttpResponse<{ data: IApiResponse<T> }>;

      // Process HTTP Result
      if (ret.status === 200) {
        if (ret.body?.data?.success === true) {
          return Promise.resolve(ret.body?.data);
        } else {
          const errMsg = this.handleSuccessFalseMessage(ret);
          return Promise.reject(errMsg);
        }
      }

      return Promise.reject(
        "Network request failed with status #" +
          `${ret ? (ret as any).status : "ERR"}`
      );
    } catch (err) {
      if (err === "network-request-failed") {
        const errResponse: IApiResponse = { success: false, message: err };
        return Promise.resolve(errResponse as IApiResponse<T>);
      }
      return Promise.reject(err);
    }
  }

  /**
   * Handle the available message options for success = false
   * @param ret http server response
   * @returns string message for error rejection
   */
  handleSuccessFalseMessage(ret: any): string {
    if (ret.data) {
      if (ret.data.message) {
        return `${ret.data.message} (Status #${ret.status})`;
      }
    }
    if (ret.message) {
      return `${ret.message} (Status #${ret.status})`;
    } else if (ret && (ret as any).error?.message) {
      return `${(ret as any).error.message} (Status #${ret.status})`;
    }
    return `Unknown Error (Status #${ret.status})`;
  }

  /**
   * Generate the HttpHeaders for a standard api request
   * @param token
   * @returns
   */
  private getHeaders(token: string | null): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append("Content-Type", "application/json");
    httpHeaders = httpHeaders.append(
      "Ocp-Apim-Subscription-Key",
      environment.apiSubscriptionKey
    );
    httpHeaders = httpHeaders.append("Ocp-Apim-Trace", "false");
    if (token) {
      httpHeaders = httpHeaders.append("token", token);
    }
    return httpHeaders;
  }

  /**
   * Generate the HttpHeaders for report server request
   * @param token
   * @returns
   */
  private getReportHeaders(token: string | null): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append("Content-Type", "application/json");
    httpHeaders = httpHeaders.append(
      "Ocp-Apim-Subscription-Key",
      environment.reportSubscriptionKey
    );
    httpHeaders = httpHeaders.append("Ocp-Apim-Trace", "false");
    if (token) {
      httpHeaders = httpHeaders.append("token", token);
    }
    return httpHeaders;
  }

  /**
   * Generate the HttpHeaders for multipart-file uploads
   * @param token
   * @returns
   */
  private getUploadHeaders(token: string | null): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append("Content-Type", "multipart/form-data");
    httpHeaders = httpHeaders.append(
      "Ocp-Apim-Subscription-Key",
      environment.apiSubscriptionKey
    );
    httpHeaders = httpHeaders.append("Ocp-Apim-Trace", "false");
    if (token) {
      httpHeaders = httpHeaders.append("token", token);
    }
    return httpHeaders;
  }

  /**
   * Get firebase auth token
   * @since 1.2.0
   */
  private async getToken(): Promise<string | null> {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken(true);
    if (token) {
      return token;
    }
    return null;
  }
}

type _AngularRequestOptions = {
  body?: any;
  headers?: HttpHeaders;
  context?: HttpContext;
  observe?: "response" | "body" | "events" | undefined;
  responseType: "json" | "arraybuffer" | "blob" | "text" | undefined;
  params?: HttpParams;
  reportProgress?: boolean;
  withCredentials?: boolean;
};

type _AngularGetRequestOptions = {
  headers?: HttpHeaders;
  context?: HttpContext;
  observe?: "body" | undefined;
  responseType: "json" | undefined;
  params?: HttpParams;
  reportProgress?: boolean;
  withCredentials?: boolean;
};

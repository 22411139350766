/**
 * Local Preference Keys
 */
export enum StorageKey {
  /**
   * Whether to display defects in the sidepanel view or in a modal
   */
  defectSidePanel = "defect-side-panel",
  /**
   * Controls default option for the Comments, History, or All component
   */
  defectActivityOption = "defect-activity-option",
  tenantId = "tenantId",
  userId = "userId",
  tier = "tier",
  admin = "admin",
  enabled = "enabled",
  username = "username",
  clientUserId = "clientuserid",
  clientTenantId = "clienttenantid",
}

import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { environment } from "src/environments/environment";
import { MixpanelService } from "./mixpanel.service";

@Injectable({
  providedIn: "root",
})
export class LogService {
  constructor(private mixpanel: MixpanelService) {}

  /**
   * SENTRY
   * - Set user data against sentry errors
   * @param user
   */
  identifyUser(
    user: { email: string | null; id?: string | null; paid?: boolean } | null
  ) {
    if (!environment.production) {
      console.log("Sentry, registering user", user);
    }
    try {
      if (user === null) {
        Sentry.setUser(null);
        this.mixpanel.identifyUser(null);
      } else {
        Sentry.setUser({
          email: user.email ?? undefined,
          id: user.id || undefined,
          // [key: string]: any;
          // ip_address?: string;
          // username?: string;
          segment:
            user.paid !== undefined
              ? user.paid === true
                ? "premium"
                : "free"
              : undefined,
        });
        this.mixpanel.identifyUser(user.id ?? null);
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  /**
   * Logs an info level message
   * @param message Information to log
   */
  info(message: string): void {
    console.log(message);
    if (message) {
      if (environment.production) {
        this.log(message, "info");
      } else {
        this.log(`Info: ${message}`, "debug");
      }
    }
  }

  /**
   * Logs an exception level message
   * @param error Error to log
   */
  exception(exception: any): void {
    console.error(exception);
    if (exception) {
      if (environment.production) {
        try {
          Sentry.captureException(exception);
        } catch (ex) {
          console.error(ex);
        }
      } else {
        this.log(`Exception: ${exception}`, "debug");
      }
    }
  }

  /**
   * Logs an error level message
   * @param error Error to log
   */
  error(message: string): void {
    console.error(message);
    if (message) {
      if (environment.production) {
        this.log(message, "error");
      } else {
        this.log(`Error: ${message}`, "debug");
      }
    }
  }

  /**
   * Logs a warning level message
   * @param info The warning info to log
   */
  warning(message: string): void {
    console.warn(message);
    if (message) {
      if (environment.production) {
        this.log(message, "warning");
      } else {
        this.log(`Warning: ${message}`, "debug");
      }
    }
  }

  /**
   * Adds a basic Sentry breadcrumb
   *
   * This is sent to Sentry with log/exception's
   * @param {string} message
   */
  breadcrumb(message: string, data?: Object): void {
    let breadcrumb: Sentry.Breadcrumb | undefined;
    if (message && environment.production) {
      breadcrumb = {
        type: BreadcrumbType.default,
        category: "info",
        level: "info",
        message: message,
        timestamp: Date.now() / 1000,
        data,
      };
    } else {
      breadcrumb = {
        type: BreadcrumbType.debug,
        category: "info",
        level: "debug",
        message: message,
        timestamp: Date.now() / 1000,
        data,
      };
    }

    if (breadcrumb) {
      try {
        Sentry.addBreadcrumb(breadcrumb);
      } catch (ex) {
        console.error(ex);
      }
    }
  }

  /**
   * Sentry captureMessage
   * @param message
   * @param level "info" | "warning" | "error | debug"
   */
  private log(message: string, level: Sentry.SeverityLevel): void {
    if (message) {
      try {
        Sentry.captureMessage(message, level);
      } catch (ex) {
        console.error(ex);
      }
    }
  }
}

/**
 * https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
 */
enum BreadcrumbType {
  /**
   * Describes a generic breadcrumb. This is typically a log
   * message or user-generated breadcrumb. The data part is
   * entirely undefined and as such, completely rendered as
   * a key/value table.
   */
  default = "default",
  /**
   * This is typically a log message. The data part is entirely
   * undefined and as such, completely rendered as a key/value table.
   */
  debug = "debug",
  /** An error that occurred before the exception. */
  error = "error",
}

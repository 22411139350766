import { Injectable } from "@angular/core";

import { LogService } from "./log.service";

import {
  GALoginMethod,
  DefectWiseProducts,
  ProductItem,
  ProductPricingTier,
  ProductComponents,
} from "../models";

import * as Sentry from "@sentry/browser";

import { environment } from "../../environments/environment";

import mixpanel from "mixpanel-browser";
declare var gtag: any;

export enum ANALYTICS_EVENTS {
  listEdited = "list_details_edited",
  listCreated = "list_created",
  projectEdited = "project_details_edited",
  projectCreated = "project_created",
  teamInvitedUser = "team_invited_user",
  contractorDeleted = "contractor_delete",
  contractorCreated = "contractor_created",
  contractorEdited = "contractor_edit",
  viewStripeBillingPortal = "view_stripe_billing_portal",
  loginRegisterSkipped = "register-skipped",
  emailAccountCreated = "email_account_created",
  defectEdited = "defect_edited",
  defectCreated = "defect_created",
  defectViewShowPanel = "defect_view_show_panel",
  defectViewShowModal = "defect_view_show_modal",
  signUp = "sign_up",
}

/** Firebase Analytics service */
@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  /** Default currency */
  private readonly currency: "AUD" = "AUD";
  /** Default unit cost */
  private readonly unitCost: number = 19;
  // private readonly webAppCombinedGa4: string = "G-ZN5NYCEP0C";
  /** @ignore */
  constructor(private log: LogService) {}

  /**
   * Init gtag tracker in app.component
   */
  init(tag?: string): void {
    try {
      const { ga4measurementTag } = environment;
      gtag("config", tag ?? ga4measurementTag, {
        send_page_view: false,
        allow_enhanced_conversions: true,
      });

      gtag("consent", "update", {
        ad_user_data: "granted",
        ad_personalization: "granted",
        ad_storage: "granted",
        analytics_storage: "granted",
      });
    } catch (ex) {
      //
    }
  }

  /**
   * Sets the Enhanced Analytics email parameter
   * @param email
   */
  identifyUser(email: string): void {
    if (!environment.production) {
      console.log("Google Analytics - Set User", email);
    }

    try {
      gtag("set", "user_data", {
        email: email,
      });
    } catch (ex) {
      //
    }
  }

  /**
   * Generic page view event
   * > Also sets the page title `document.title = pageTitle`
   * @param pageTitle
   * @param pageRoute page path WITHOUT leading /
   */
  pageView(pageTitle: string, pageRoute: string): void {
    document.title = pageTitle;

    try {
      const { ga4measurementTag } = environment;
      gtag("config", ga4measurementTag, {
        page_title: pageTitle,
        page_location: `https://account.defectwise.com.au/${pageRoute}`, // The full URL is required.
      });

      mixpanel.track("page_view", {
        page_title: pageTitle,
        page_location: `https://account.defectwise.com.au/${pageRoute}`,
      });
    } catch (ex) {
      //
    }

    this.addSentryBreadcrumb(pageRoute);
  }

  /**
   * Sets the screename and screen_view
   * event to the current page
   * @param pageName Name of page being visited
   */
  private addSentryBreadcrumb(pageName: string): void {
    try {
      Sentry.addBreadcrumb({
        category: "screen_view",
        message: pageName,
        level: "info",
      });
    } catch (ex) {
      //
    }
  }

  trackCreateDefect(props: { hasAssignment: boolean }): void {
    try {
      mixpanel.track("create_defect", props);
      this.trackCustomEvent(ANALYTICS_EVENTS.defectCreated);
    } catch (ex) {
      console.error(ex);
    }
  }

  /**
   * Sends a custom event to Firebase Analytics
   * @param eventName Event to be posted
   * @param eventProps Details of event
   */
  trackCustomEvent(eventName: ANALYTICS_EVENTS, eventProps?: any) {
    try {
      gtag("event", eventName, eventProps ?? {});
    } catch (ex) {
      //
    }

    try {
      if (eventProps !== undefined) {
        Sentry.addBreadcrumb({
          category: "custom_event",
          message: eventName,
          level: "info",
          data: {
            ...(eventProps ?? {}),
          },
        });
      } else {
        Sentry.addBreadcrumb({
          category: "custom_event",
          message: eventName,
          level: "info",
        });
      }
    } catch (ex) {
      //
    }
  }

  /**
   * GA4 Login Event `login`
   * - https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
   * @param method
   */
  login(method: GALoginMethod | string, email: string): void {
    if (!environment.production) {
      console.log("Google Analytics - Set User");
    }
    try {
      this.identifyUser(email);
      gtag("event", "login", { method });
    } catch (ex) {
      //
    }
  }

  /**
   * GA4 Login Event `sign_up`
   * - https://developers.google.com/analytics/devguides/collection/ga4/reference/events#sign_up
   * @param method
   */
  signUp(method: GALoginMethod, email: string): void {
    try {
      this.identifyUser(email);
      gtag("event", ANALYTICS_EVENTS.signUp, { method });
    } catch (ex) {
      //
    }
    try {
      const { ga4measurementTag } = environment;
      gtag("get", ga4measurementTag, "gclid", (gclid?: string) => {
        mixpanel.track(ANALYTICS_EVENTS.signUp, {
          sign_up_method: method,
          gclid: gclid,
        });
      });
    } catch (ex) {
      //
    }
  }

  /**
   * ## AD-WORDS
   *
   * - Runs on get-app install page (triggered by the QR Codes)
   * - Triggers the `view_app_store` event for iOS users
   *
   */
  viewAppStore(): void {
    try {
      gtag("event", "conversion", {
        send_to: "AW-11205412364/YXVbCMm-rtQZEIyMlN8p",
      });
    } catch (ex) {
      this.log.warning(JSON.stringify(ex));
    }
  }

  /**
   * ## AD-WORDS
   *
   * - Runs on get-app install page (triggered by the QR Codes)
   * - Triggers the `scan_qr_code` event
   *
   */
  scanQrCode(): void {
    try {
      gtag("event", "conversion", {
        send_to: "AW-11205412364/2NeKCPzwpdgZEIyMlN8p",
      });
    } catch (ex) {
      this.log.warning(JSON.stringify(ex));
    }
  }

  /**
   * Run when billing page loads
   * - https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_cart
   */
  viewCart(
    productId: DefectWiseProducts,
    quantity: number,
    promoCode?: string
  ): void {
    try {
      const discountFactor = this._getDiscountFactor(promoCode);
      const { value, item } = this.getProductData(
        productId,
        quantity,
        discountFactor
      );
      const eventName = "view_cart";

      if (item) {
        mixpanel.track(eventName, {
          cart: [item],
          coupon: promoCode,
          cart_value: value,
        });

        gtag("event", eventName, {
          currency: this.currency,
          value,
          coupon: promoCode,
          items: [item],
        });
      }
    } catch (ex) {
      this.log.warning(JSON.stringify(ex));
    }
  }

  /**
   * Triggered on billing page clicking checkout
   * - https://developers.google.com/analytics/devguides/collection/ga4/reference/events#begin_checkout
   */
  beginCheckout(
    productId: DefectWiseProducts,
    quantity: number,
    promoCode?: string
  ): void {
    const eventName = "begin_checkout";

    try {
      const discountFactor = this._getDiscountFactor(promoCode);
      const { value, item } = this.getProductData(
        productId,
        quantity,
        discountFactor
      );

      if (item) {
        mixpanel.track(eventName, {
          cart: [item],
          coupon: promoCode,
          cart_value: value,
        });

        gtag("event", eventName, {
          currency: this.currency,
          value: value,
          coupon: promoCode,
          items: [item],
        });
      }
    } catch (ex) {
      this.log.warning(JSON.stringify(ex));
    }
  }

  /**
   * Run on confirmation page
   * - https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase
   *
   * @param transaction_id Unique reference - The `transaction_id` parameter helps you avoid getting duplicate events for a purchase
   * @param productId `DefectWiseProducts`
   * @param quantity
   */
  purchase(
    transaction_id: string,
    productId: DefectWiseProducts,
    quantity: number,
    email: string | null | undefined,
    promoCode?: string,
    gclid?: string
  ): void {
    const { production } = environment;
    const eventName = "purchase";

    if (!production) {
      console.log(
        "Google Analytics - Purchase",
        transaction_id,
        productId,
        quantity
      );
    }

    const discountFactor = this._getDiscountFactor(promoCode);

    try {
      if (gclid) {
        gtag("set", { gclid });
      }
      if (email) {
        this.identifyUser(email);
      }

      const { value, item } = this.getProductData(
        productId,
        quantity,
        discountFactor
      );

      if (item) {
        try {
          mixpanel.track(eventName, {
            cart: [{ ...item, value }],
            coupon: promoCode,
            cart_value: value,
          });
        } catch (ex) {
          // ignore
        }
        gtag("event", eventName, {
          transaction_id,
          coupon: promoCode,
          value: value,
          tax: 0,
          currency: this.currency,
          items: [item],
        });

        setTimeout(() => {
          gtag("event", "conversion", {
            send_to: "AW-11205412364/ukrhCK2cgJwZEIyMlN8p",
            transaction_id,
            coupon: promoCode,
            value: value,
            tax: 0,
            currency: this.currency,
            items: [item],
          });
        });
      }
    } catch (ex) {
      console.error(ex);
      this.log.warning(JSON.stringify(ex));
    }
  }

  /**
   * Takes the last two characters from discount code and converts to number
   *
   * @param {string | undefined} promoCode
   * @returns {number} discount as a whole number, zero if not found
   */
  private _getDiscountFactor(promoCode?: string): number {
    let discount = 0;
    try {
      if (promoCode) {
        const lastTwo = Number(promoCode.slice(-2));
        if (!isNaN(lastTwo)) {
          discount = Math.round(lastTwo) / 100;
        }
      }
    } catch (ex) {
      console.error(ex);
    }
    return discount;
  }

  /**
   * Calculate total cost and get product from environment file
   * @param productId
   * @param quantity
   * @returns {ProductComponents} total value & item w/ discount and quantity information
   *
   * Note: this was last modded 16/05/23 to work with purchase event - not tested with others
   */
  private getProductData(
    productId: DefectWiseProducts,
    quantity: number,
    discountFactor: number
  ): ProductComponents {
    const { products, pricingTiers }: any = environment;
    const price: number = this.unitCost * 12;

    const product: { item_id: string; item_name: string } = products[productId];

    const tiers: { quantity: number; cost: number }[] =
      pricingTiers[productId] ?? [];

    const tier: ProductPricingTier | undefined = tiers.find(
      (o: ProductPricingTier) => o.quantity >= quantity
    );

    if (!product || !tier) {
      throw Error("Unable to determine product selection.");
    }

    // total discount for a year period
    let totalUnitDiscount =
      Math.round(100 * 12 * (this.unitCost - tier.cost)) / 100;
    if (totalUnitDiscount < 0) totalUnitDiscount = 0;

    if (discountFactor > 0) {
      const priceBefore = price - totalUnitDiscount;
      totalUnitDiscount = totalUnitDiscount + priceBefore * discountFactor;
      totalUnitDiscount = Math.round(100 * totalUnitDiscount) / 100;
    }

    // total value of purchase * quantity
    const value =
      Math.round(
        100 * 12 * ((tier.cost - tier.cost * discountFactor) * quantity)
      ) / 100;

    const item: ProductItem = {
      // item_id: "SKU_12345",
      item_id: product.item_id,
      // item_name: "Stan and Friends Tee",
      item_name: product.item_name,
      // price: 9.99,
      price: price - totalUnitDiscount,
      // discount: 2.22,
      discount: totalUnitDiscount,
      // quantity: 1
      quantity,
    };

    return { value, item: item };
  }
}

import { Injectable } from "@angular/core";
import type {
  IApiResponse,
  Endpoint,
  IHttpLogin,
  IHttpUser,
  IHttpUsers,
  IHttpGetTenantTier,
  ProjectUserType,
  PrimaryKeyId,
  IHttpCheckUser,
} from "src/app/models";
import { IApiResponseBase, ICreateUser, ISetUser } from "src/app/models/http";
import { TenantRole } from "src/app/models/tenant.model";
import { HTTP_METHOD } from ".";
import { HttpService } from "../http.service";
import { UserService } from "../user.service";

@Injectable({
  providedIn: "root",
})
export class UserApiService {
  private _checkUser = "CheckUser" as Endpoint;
  private _login = "Login" as Endpoint;
  private _getUser = "GetUser" as Endpoint;
  private _getUsers = "GetUsers" as Endpoint;
  private _createUser = "CreateUser" as Endpoint;
  private _setUser = "SetUser" as Endpoint;
  // private _deleteUser = "DeleteUser" as Endpoint;
  private _getTenantTier = "GetTenantTier" as Endpoint;
  private _editTenantRole = "EditTenantRole" as Endpoint;
  private _editUserStatus = "EditUserStatus" as Endpoint;

  constructor(private http: HttpService, private us: UserService) {}

  /**
   * Retrieves a users details
   * @returns {Promise<IApiResponse<IHttpCheckUser>>} User data
   * 
   * @since 2.6.9
   */
  async checkUser(email: string): Promise<IApiResponse<IHttpCheckUser>> {
    return this.http.request<IHttpCheckUser>(
      HTTP_METHOD.GET,
      this._checkUser,
      { email },
      null
    );
  }

  /**
   * "Login" occurs after Firebase auth to retrieve user details from cloud DB
   * @returns {Promise<IApiResponse<IHttpLogin>>} User data
   */
  async login(): Promise<IApiResponse<IHttpLogin>> {
    return this.http.request<IHttpLogin>(
      HTTP_METHOD.POST,
      this._login,
      null,
      {}
    );
  }

  /**
   * Retrieves a users details
   * @returns {Promise<IApiResponse<IHttpUser>>} User data
   */
  async getUser(): Promise<IApiResponse<IHttpUser>> {
    const userId = await this.us.checkAndGetUserId();
    return this.http.request<IHttpUser>(
      HTTP_METHOD.GET,
      this._getUser,
      { userId },
      null
    );
  }

  /**
   * TODO: DMS-1309 Account Page - Not working
   * Retrieves a users details
   * @returns {Promise<IApiResponse<IHttpUser>>} User data
   */
  async getClientUser(): Promise<IApiResponse<IHttpUser>> {
    const clientIds = await this.us.checkAndGetClientIds();
    return this.http.request<IHttpUser>(
      HTTP_METHOD.GET,
      this._getUser,
      { userId: clientIds.userId },
      null
    );
  }

  /**
   * Retrieves a users details
   * @returns {Promise<IApiResponse<IHttpUser>>} User data
   */
  async getUsers(): Promise<IApiResponse<IHttpUsers>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IHttpUsers>(
      HTTP_METHOD.GET,
      this._getUsers,
      { tenantId },
      null
    );
  }

  /**
   * Creates a new user (Currently used only by Web-portal)
   * @param {ICreateUser} user
   * @returns {Promise<IApiResponse<ApiCreateUser>>} The new TENANT ID and lastmodified
   */
  async createUser(user: ICreateUser): Promise<IApiResponse<ApiCreateUser>> {
    return this.http.request<ApiCreateUser>(
      HTTP_METHOD.POST,
      this._createUser,
      null,
      user
    );
  }

  /**
   * Updates an existing users data
   * @param {ISetUser} user User data to update
   * @returns {Promise<IApiResponse>} Success and message
   */
  async setUser(user: ISetUser): Promise<IApiResponse> {
    return this.http.request(HTTP_METHOD.PUT, this._setUser, null, user);
  }

  /**
   * Deletes a user and all associated project data
   * @param {number} tenantId User tenantId
   * @param {string} fbId User Firebase Id
   * @returns {Promise<IApiResponse>} Success and message
   */
  // async deleteUser(tenantId: number, fbId: string): Promise<IApiResponse> {
  //   return this.http.request(HTTP_METHOD.DELETE, this._deleteUser, null, {
  //     tenantId,
  //     fbId,
  //   });
  // }

  /**
   * Get the current users tenant tier data
   * @returns {Promise<IApiResponse<IHttpGetTenantTier>>} Tenant tier and defect count
   */
  async getTenantTier(): Promise<IApiResponse<IHttpGetTenantTier>> {
    const ids = await this.us.checkAndGetUserIds();
    return this.http.request<IHttpGetTenantTier>(
      HTTP_METHOD.GET,
      this._getTenantTier,
      { tenantId: ids.tenantId, userId: ids.userId },
      null
    );
  }

  /**
   * Updates a user's role against a tenant
   * @param {PrimaryKeyId} userId
   * @param {string} role
   * @param {string} entityType
   * @returns {Promise<IApiResponseBase>}
   */
  async editTenantRole(
    userId: PrimaryKeyId,
    role: TenantRole,
    entityType: ProjectUserType
  ): Promise<IApiResponseBase> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IApiResponseBase>(
      HTTP_METHOD.PUT,
      this._editTenantRole,
      null,
      {
        tenantId,
        userId,
        role,
        entityType,
      }
    );
  }

  /**
   * Updates a users archived status
   * @param {PrimaryKeyId} userId
   * @param {boolean} archived
   * @returns {Promise<IApiResponseBase>}
   */
  async editUserStatus(
    userId: PrimaryKeyId,
    archived: boolean
  ): Promise<IApiResponseBase> {
    return this.http.request<IApiResponseBase>(
      HTTP_METHOD.PUT,
      this._editUserStatus,
      null,
      {
        userId,
        archived,
      }
    );
  }
}

interface ApiCreateUser {
  readonly id: number;
  readonly lastModified: string;
  readonly userId: number;
}

import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";

import type {
  IApiResponse,
  IHttpApiUpdateResponse,
  Endpoint,
  IHttpDefect,
  IHttpSetDefectCount,
  IHttpEditDefect,
  ProjectPrimaryKeyId,
  DefectPrimaryKeyId,
  ListPrimaryKeyId,
  PrimaryKeyId,
  IHttpDefectLog,
} from "../../models";

import {
  ICreateDefect,
  IEditDefect,
  IEditDefectContractor,
  IEditDefectPhoto,
  IHttpCreateDefect,
  ICreateDefectPhoto,
  ISetDefectStatus,
} from "../../models";

import { UserService } from "../user.service";
import { HTTP_METHOD } from ".";

@Injectable({
  providedIn: "root",
})
export class DefectApiService {
  private _getDefect = "GetDefect" as Endpoint;
  private _setDefectCount = "SetDefectCounter" as Endpoint;
  private _createDefect = "CreateDefect" as Endpoint;
  private _editDefect = "EditDefect" as Endpoint;
  private _deleteDefect = "DeleteDefect" as Endpoint;
  private _setDefectStatus = "SetDefectStatus" as Endpoint;
  private _moveDefects = "movedefects" as Endpoint;
  private _getDefectHistory = "GetDefectHistory" as Endpoint;
  /** @ignore */
  constructor(private http: HttpService, private us: UserService) {}
  /**
   * Gets the details of an individual defect
   * @param {DefectPrimaryKeyId} defectId Cloud Id of the defect
   * @param {ProjectPrimaryKeyId} projectId Cloud Id of the project
   * @returns
   */
  async getDefect(
    defectId: DefectPrimaryKeyId,
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IHttpDefect>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IHttpDefect>(
      HTTP_METHOD.GET,
      this._getDefect,
      { tenantId, defectId, projectId },
      null,
      false
    );
  }

  /**
   * Updates the defect count number for a tenant
   * @param {number} count represents the value to increment tenant by, default 1
   * @returns {Promise<IApiResponse<IHttpSetDefectCount>>} The updated defect count
   */
  async setDefectCount(
    count?: number | undefined
  ): Promise<IApiResponse<IHttpSetDefectCount>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IHttpSetDefectCount>(
      HTTP_METHOD.PUT,
      this._setDefectCount,
      null,
      { count: count ?? 1, tenantId }
    );
  }

  /**
   * Creates a new defect
   * @param {ICreateDefect} defect Data for new defect
   * @param {number[]} contractorIds ContractorId's to add to the defect
   * @param {IPhoto[]} photos
   * @param {number} projectId Cloud Id of the project
   * @returns {Promise<IApiResponse<IApiUpdateResponse>>} New cloud Id and lastmodified
   */
  async createDefect(
    defect: ICreateDefect,
    contractorIds: PrimaryKeyId[],
    photos: ICreateDefectPhoto[],
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IHttpCreateDefect>> {
    const ids = await this.us.checkAndGetUserIds();
    return this.http.request<IHttpCreateDefect>(
      HTTP_METHOD.POST,
      this._createDefect,
      null,
      {
        tenantId: ids.tenantId,
        userId: ids.userId,
        defect,
        contractorIds,
        photos,
        projectId,
      }
    );
  }

  /**
   * Update an existing defect
   * @param {IEditDefect} defect Defect data to update
   * @param {IEditDefectContractor[]} contractors Contractors to add or remove from the defect
   * @param {IEditDefectPhoto[]} photos
   * @param {number} projectId Cloud Id of the project
   * @returns {Promise<IApiResponse<IApiUpdateResponse>>} CloudId and lastmodified
   */
  async editDefect(
    defect: IEditDefect,
    contractors: IEditDefectContractor[],
    photos: IEditDefectPhoto[],
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IHttpEditDefect>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IHttpEditDefect>(
      HTTP_METHOD.PUT,
      this._editDefect,
      null,
      { tenantId, defect, contractors, photos, projectId }
    );
  }

  /**
   * Update an existing defect status
   * @param {ISetDefectStatus} data obj > cloudId, new status, completed date
   * @param {ProjectPrimaryKeyId} projectId Cloud Id of the project
   * @returns {Promise<IApiResponse<IHttpApiUpdateResponse>>} CloudId and lastmodified
   */
  async setDefectStatus(
    data: ISetDefectStatus,
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IHttpApiUpdateResponse>> {
    return this.http.request<IHttpApiUpdateResponse>(
      HTTP_METHOD.PUT,
      this._setDefectStatus,
      null,
      { ...data, projectId }
    );
  }

  /**
   * Deletes a defect from the database
   * @param {DefectPrimaryKeyId} defectId Cloud Id of the defect
   * @param {ProjectPrimaryKeyId} projectId Cloud Id of the project
   * @returns
   */
  async deleteDefect(
    defectId: DefectPrimaryKeyId,
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IApiResponse>(
      HTTP_METHOD.DELETE,
      this._deleteDefect,
      null,
      { tenantId, defectId, projectId },
      false
    );
  }

  /**
   * EVERYTHING BELOW THIS LINE HAS NOT BEEN ADDED TO FLUTTER
   */

  /**
   * Move a list of defects to another list - list must exist
   *
   * @param {ListPrimaryKeyId} listId
   * @param {DefectPrimaryKeyId[]} defectIds
   * @returns
   */
  async moveDefects(
    listId: ListPrimaryKeyId,
    defectIds: DefectPrimaryKeyId[]
  ): Promise<IApiResponse<IApiMoveDefectResponse>> {
    return this.http.request<IApiMoveDefectResponse>(
      HTTP_METHOD.PUT,
      this._moveDefects,
      null,
      {
        siteId: listId,
        defectIds: defectIds,
      }
    );
  }

  /**
   * Gets the defects' history log
   * @param {DefectPrimaryKeyId} defectId Cloud Id of the defect
   * @returns
   */
  async getDefectHistory(
    defectId: DefectPrimaryKeyId
  ): Promise<IApiResponse<IHttpDefectLog[]>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IHttpDefectLog[]>(
      HTTP_METHOD.GET,
      this._getDefectHistory,
      { tenantId, defectId },
      null,
      false
    );
  }
}

/** Response from all edit endpoints (W.I.P) */
type IApiMoveDefectResponse = {
  readonly defectIds: { id: number; lastModified: number }[];
  readonly projectLastModified: number;
};

import { Injectable } from "@angular/core";

import { CatchErrorService } from "./catch.service";
import { UserService } from "./user.service";
import { ApiService } from "./api";

import type { IHttpApiUpdateResponse } from "../models/http";
import { IApiResponse } from "../models/http";

import type { IContractor, IProjectContractorListItem } from "../models";
import {
  IHttpContractor,
  Project,
  IGetProjectContractor,
  ProjectPrimaryKeyId,
  PrimaryKeyId,
} from "../models";

@Injectable({
  providedIn: "root",
})
export class ContractorService {
  /** @ignore */
  constructor(
    private api: ApiService,
    private cx: CatchErrorService,
    private us: UserService
  ) {}

  /**
   * Get ALL contractors for the Options > Contractors management view
   * - Run Sync Operations
   *
   * @param online whether to attempt to get data from server or not
   * @returns {IContractor[]} Active and archived contractors saved on device
   */
  async getEditContractors(): Promise<IContractor[]> {
    let contractors: IContractor[] = [];

    try {
      // Join and sort online/offline
      const result = await this.api.contractor.getContractors();

      if (result.success && result.data) {
        contractors = [
          ...result.data.map((o) => {
            return {
              ...o,
              isAssigned: o.hasDefects > 0 || false,
              lastMapChanged: o.lastModified,
            };
          }),
        ];
      }

      // Re-sort after concat
      // contractors = _.sortBy(contractors, ['company', 'name']);
    } catch (ex) {
      await this.cx.handle(
        ex,
        true,
        "Unable to retreive server data. Displaying previously synced device data."
      );
    }

    return Promise.resolve(contractors);
  }

  /**
   * Get ALL ACTIVE contractors
   * - Returns if contractor is assigned to project
   *
   * @returns {IProjectContractorListItem[]} Active and archived contractors saved on device
   */
  async getProjectContractors(
    project: Project.Project
  ): Promise<IProjectContractorListItem[]> {
    // console.log("getProjectContractors()", project);
    let contractors: IProjectContractorListItem[] = [];

    try {
      if (project.id > 0) {
        const cloudResult = await this.api.projects.getProjectContractors(
          project.id
        );

        contractors = cloudResult.data
          .filter((o) => !o.deleted || o.projectId >= 0)
          .map<IProjectContractorListItem>((o: IGetProjectContractor) => {
            return {
              ...o,
              projectMapId: null,
              contractorCloudId: o.id,
              defaultAssignment: o.defaultAssignment === 1,
              selected: o.projectId > 0,
              display: true,
            };
          });
      }
    } catch (ex) {
      this.cx.handle(
        ex,
        true,
        "Unable to retreive contractors from server, displaying synced options only. "
      );
    }

    return contractors;
  }

  /**
   * Save a builder contractor to the server then save locally with cloudId
   *
   * @param contractor
   * @returns {boolean} success
   */
  async saveContractor(contractor: IContractor): Promise<IApiResponse> {
    // console.log("saveContractors()", contractor);
    try {
      const apiResult: IApiResponse<IHttpApiUpdateResponse> = contractor.cloudId
        ? await this.api.contractor.editContractor({
            company: contractor.company,
            name: contractor.name,
            email: contractor.email,
            phone: contractor.phone,
            discipline: contractor.discipline,
            status: contractor.status,
            deleted: 0,
            cloudId: contractor.cloudId,
          })
        : await this.api.contractor.createContractor(contractor);

      return Promise.resolve(apiResult);
    } catch (ex) {
      this.cx.handle(ex);
      return Promise.resolve({
        success: false,
        message: `${ex}`,
        data: null,
      });
    }
  }

  /**
   * Updates the `Project_Contractor_Map` Table
   *
   * @param {PrimaryKeyId} contractorCloudId - cloudId
   * @param {ProjectPrimaryKeyId} projectId - reference id
   * @param {boolean} addRelationship - whether to add or remove the relationship
   * @returns
   */
  async setProjectRelationships(
    contractorCloudId: PrimaryKeyId,
    projectId: ProjectPrimaryKeyId,
    addRelationship: boolean
  ): Promise<{ success: boolean }> {
    try {
      let serverChanged = false;

      if (this.us.premium === undefined) {
        await this.us.awaitPremium();
      }

      if (this.us.premium && projectId > 0) {
        if (addRelationship) {
          const addCloudResult = await this.api.projects.addProjectContractor(
            projectId,
            contractorCloudId
          );
          serverChanged = addCloudResult.success;
        } else {
          const removeCloudResult =
            await this.api.projects.removeProjectContractor(
              projectId,
              contractorCloudId
            );
          serverChanged = removeCloudResult.success;
        }
      }

      return Promise.resolve({ success: serverChanged });
    } catch (ex) {
      return Promise.reject(ex);
    }
  }

  /**
   * Updates the `defaultAssignment` on `Project_Contractor_Map` Table
   *
   * @param {IProjectContractor} contractor id of map row
   * @param {boolean} defaultAssignment converts to number for sql
   * @param {ProjectPrimaryKeyId} projectCloudId
   * @returns {boolean}
   */
  async setProjectDefault(
    contractor: Project.Contractor,
    defaultAssignment: boolean,
    projectCloudId: ProjectPrimaryKeyId
  ): Promise<boolean> {
    try {
      let serverChanged = false;

      if (this.us.premium === undefined) {
        await this.us.awaitPremium();
      }

      if (this.us.premium && projectCloudId > 0) {
        const cloudResult = await this.api.projects.editProjectContractor(
          projectCloudId,
          contractor.contractorCloudId,
          defaultAssignment
        );
        serverChanged = cloudResult.success;
      }

      return Promise.resolve(serverChanged);
    } catch (ex) {
      return Promise.reject(ex);
    }
  }

  /**
   * Delete a contractor from the server then if success delete locally
   * - UI only allows delete when no `Project_Contractor_Map` or `Defect_Contractor_Map` relationships exist
   *
   * @param {IContractor} contractor
   * @returns
   */
  async deleteContractor(contractor: IContractor): Promise<IApiResponse> {
    // console.log("deleteContractor()", contractor);
    try {
      const response: IApiResponse = await this.api.contractor.deleteContractor(
        contractor.cloudId!
      );

      return Promise.resolve({
        success: response.success,
        message: response.message,
        data: null,
      });
    } catch (ex) {
      this.cx.handle(ex);
      return Promise.resolve({
        success: false,
        message: JSON.stringify(ex),
        data: null,
      });
    }
  }
}

import { HttpResponse } from "@capacitor/core/types/core-plugins";
import {
  Endpoint,
  ListPrimaryKeyId,
  PrimaryKeyId,
  ProjectPrimaryKeyId,
} from "../brands.model";

/**
 * Used by the HTTP interceptor to determine which user ids to test
 */
export enum PERMISSION_CHECK_TYPE {
  GENERAL = "general",
  CLIENT = "client",
  BUILDER = "builder",
}

export interface IHttpResponse<T> extends HttpResponse {
  readonly data: T;
}

/** Base API Response always has these two props */
export type IApiResponseBase = {
  readonly message: string;
  readonly success: boolean;
};

/** Extension API Response will have data of a generic type T */
export type IApiResponseData<T> = {
  readonly message: string;
  readonly success: boolean;
  readonly data: T;
};

/** Response from all edit endpoints (W.I.P) */
export type IHttpApiUpdateResponse = {
  readonly cloudId: ProjectPrimaryKeyId | ListPrimaryKeyId | PrimaryKeyId;
  readonly lastModified: number;
  readonly projectLastModified: number;
};

/** Create a dynamic type for the overall API Response.
 * If a type of response data is provided then return the API response interface with data
 * Otherwise revert to the standard base API response
 */
export type IApiResponse<T = void> = T extends void
  ? IApiResponseBase
  : IApiResponseData<T>;

/**
 * Type for Permissions Check Interceptor HTTP Params
 */
export type IApiPermissionParams = {
  readonly endpoint: Endpoint;
  readonly userId: string;
  readonly tenantId: string;
  projectId?: string | undefined;
};

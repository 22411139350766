import {
  HttpClient,
  HttpContext,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { CapacitorHttp } from "@capacitor/core";

import { firstValueFrom, from, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { NavController, AlertController } from "@ionic/angular";
import { UserService } from "./user.service";
import { IApiPermissionParams, PERMISSION_CHECK_TYPE } from "../models/http";
import type { Endpoint } from "../models";

export const SKIP_CHECK = new HttpContextToken(() => true);
export const PERMISSION_TYPE = new HttpContextToken(
  () => PERMISSION_CHECK_TYPE.GENERAL
);

const GET_USER_PERMISSION = "GetUserPermissions";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private us: UserService
  ) {}

  /**
   * Transforms the response from Capacitor HTTP to Angular HTTP
   * @param capResponse Response from Cap HTTP request
   * @returns {HttpResponse<unknown>}
   */
  private static toHttpResponse(capResponse: any): HttpResponse<unknown> {
    return new HttpResponse({
      status: capResponse.status,
      headers: new HttpHeaders(capResponse.headers),
      url: capResponse.url,
      body: capResponse,
    });
  }

  /**
   * Transforms the Angular HTTP Client request into a Capacitor HTTP Plugin request
   * @param {HttpRequest<any>} req The Angular HTTP request object
   * @returns {Observable<HttpResponse<unknown>>}
   */
  private makeApiCall(
    req: HttpRequest<any>
  ): Observable<HttpResponse<unknown>> {
    const headers = req.headers
      .keys()
      .reduce((o, key) => ({ ...o, [key]: req.headers.get(key) }), {});
    const params = req.params
      .keys()
      .reduce((o, key) => ({ ...o, [key]: req.params.get(key) }), {});
    const data = req.body === null ? undefined : req.body;
    let options: any = {
      method: req.method,
      url: req.url,
      responseType: req.responseType,
      headers,
      params,
      data,
    };
    return from(CapacitorHttp.request(options)).pipe(
      map<any, HttpResponse<unknown>>((capResponse) =>
        HttpInterceptorService.toHttpResponse(capResponse)
      )
    );
  }

  runPermissionsCall(
    endpoint: Endpoint,
    permissionType: PERMISSION_CHECK_TYPE,
    projectId?: number
  ) {
    return from(
      Promise.all([
        this.us.getUserToken(),
        this.us.checkAndGetUserIds(true),
        this.us.checkAndGetClientIds(true),
      ])
        .then((res) => {
          const token = res[0];

          let userId: number, tenantId: number;

          if (permissionType === PERMISSION_CHECK_TYPE.CLIENT) {
            userId = res[2].userId ?? -1;
            tenantId = res[2].tenantId ?? -1;
          } else {
            userId = res[1].userId ?? -1;
            tenantId = res[1].tenantId ?? -1;
          }

          const params: IApiPermissionParams = {
            endpoint,
            userId: userId.toString(),
            tenantId: tenantId.toString(),
          };
          if (projectId) {
            params.projectId = projectId.toString();
          }
          // https://indepth.dev/rxjs-heads-up-topromise-is-being-deprecated/
          return firstValueFrom(
            this.http.get<any>(environment.apiBaseUrl + GET_USER_PERMISSION, {
              context: new HttpContext().set(SKIP_CHECK, true),
              params: params as any,
              headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": environment.apiSubscriptionKey,
                "Ocp-Apim-Trace": "false",
                token: token || "",
              },
            })
          );
        })
        .catch((err) => {
          return Promise.reject(err);
        })
    );
  }

  /**
   *
   * @param {HttpRequest<any>} req Angular HTTP Request object
   * @param {HttpHandler} next Angular HTTP handler object
   * @returns {Observable<HttpEvent<any>>} The HTTP request event
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Just continue if it isn't a https request (local files/capacitor protocol etc)
    if (!req.url.includes("https://")) return next.handle(req);
    // Just continue if bypass is set true
    if (req.context.get(SKIP_CHECK) === true) {
      return this.makeApiCall(req);
    }

    if (!environment.production) {
      console.log(
        "%cREQUEST HAS BEEN INTERCEPTED",
        "color:red;font-weight:bold;"
      );
    }

    const permissionType = req.context.get(PERMISSION_TYPE);
    const _projectId: any = req.params?.get("projectId") || req.body?.projectId;
    const projectId: any = isNaN(Number(_projectId))
      ? null
      : Number(_projectId);

    const endpoint: Endpoint = this.getEndpoint(req.url);
    // Run permission check if we get this far
    return from(
      this.runPermissionsCall(endpoint, permissionType, projectId)
    ).pipe(
      // Process the response of GetUserPermissions
      switchMap((result) => {
        if (result.data.success) {
          return this.makeApiCall(req);
        } else {
          if (result.status === 401) {
            this.alertCtrl
              .create({
                header: "Error",
                subHeader: "Access Denied",
                message: result.data.message,
                buttons: [{ text: "OK" }],
              })
              .then(async (alert) => {
                await alert.present();
                await alert.onDidDismiss();
                await this.navCtrl.navigateBack("/projects");
              });
          }
          return of(result).pipe(
            map<any, HttpResponse<unknown>>((capResponse) =>
              HttpInterceptorService.toHttpResponse(capResponse)
            )
          );
        }
      })
    );
  }

  private getEndpoint(url: string): Endpoint {
    const path = url.substring(url.lastIndexOf("/") + 1, url.length);
    return path as Endpoint;
  }
}

import { Injectable } from "@angular/core";
import { getAuth, User } from "firebase/auth";
import { CapacitorHttp } from "@capacitor/core";

import { StorageService } from "./storage.service";
import { LogService } from "./log.service";
import type { IEvaluateSubscription, IHttpClientLogin } from "../models";
import { IHttpLogin, StorageKey } from "../models";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  /**
   * Boolean for whether defect limit reached
   */
  limitReached: boolean = false;
  /**
   * Current usage by user(s)
   */
  defectCount: number = 0;
  /**
   * Whether user is tenant admin
   */
  private _admin: boolean | undefined;
  /**
   * Whether user is enabled or disabled by parent organisation
   */
  enabled: boolean = true;
  /**
   * Payment tier of user
   */
  private tier: number | undefined;
  /**
   * Total number of defects that can be created
   */
  private defectLimit: number = 100;
  /**
   * Usage options for payment tiers
   * @deprecated use result from server where possible
   */
  private readonly usageOpts: number[] = [100, 2000, 100000];
  /** @ignore */
  constructor(private store: StorageService, private log: LogService) {}

  get admin(): boolean {
    return this._admin === true;
  }

  set admin(val: boolean) {
    this._admin = val;
  }

  get premium(): boolean | undefined {
    return this.tier !== undefined ? this.tier > 0 : undefined;
  }

  get enterprise(): boolean {
    return this.tier === 2;
  }

  /**
   * Init payment variable for UI display
   * ```
   * tier: number; // pass in payment tier to update service
   * admin: boolean | undefined | null; // pass in whether user is tenant admin or not
   * enabled: boolean; // archived status of user
   * defectLimit: number;
   * defectCount: number;
   * ```
   */
  evaluateSubscription(data: IEvaluateSubscription): void {
    // console.log("Evaluating Subscription, TIER: " + tier)
    this.tier = data.tier || 0;

    this.enabled = data.enabled !== undefined ? data.enabled : true;
    if (data.admin === undefined || data.admin === null) {
      this._admin = false;
    } else {
      this._admin = data.admin;
    }

    this.defectCount = data.defectCount;
    this.defectLimit =
      data.defectLimit !== undefined
        ? data.defectLimit
        : this.usageOpts[this.tier];

    this.limitReached = this.defectCount > this.defectLimit;

    if (!environment.production) {
      console.log(
        `%cSUBSCRIPTION: evaluateSubscription(): premium: ${this.premium}, admin: ${this.admin}, enabled: ${this.enabled}, defectLimit: ${this.defectLimit}, defectCount: ${this.defectCount}`,
        "background-color:blue;color:white"
      );
    }

    return;
  }
  /**
   * Has the user maxxed their defects
   * @returns
   */
  defectLimitReach(): boolean {
    this.limitReached = this.defectCount >= this.defectLimit;
    return this.limitReached;
  }
  /**
   *
   */
  async checkAndGetTenantId(): Promise<number> {
    let value = await this.store.getNumber(StorageKey.tenantId);

    if (value === null) {
      let result = await this.getLogin();
      if (result.success) {
        value = await this.store.getNumber(StorageKey.tenantId);
      } else {
        return Promise.reject("Error determining user, try again.");
      }
    }

    return value || -1;
  }
  /**
   *
   */
  async checkAndGetUserId(): Promise<number> {
    let value = await this.store.getNumber(StorageKey.userId);

    if (value === null) {
      let result = await this.getLogin();
      if (result.success) {
        value = await this.store.getNumber(StorageKey.userId);
      } else {
        return Promise.reject("Error determining user, try again.");
      }
    }

    return value || -1;
  }
  /**
   * Checks and gets the NORMAL type user IDs.
   *
   * @param {boolean} skipGetLoginOnFailure will return null values and not attempt to call GetLogin
   *
   * @return {Promise<{ userId: number | null; tenantId: number | null; }>} General user IDs.
   */
  async checkAndGetUserIds(skipGetLoginOnFailure?: boolean): Promise<{
    userId: number | null;
    tenantId: number | null;
  }> {
    const res = await Promise.all([
      this.store.getNumber(StorageKey.userId),
      this.store.getNumber(StorageKey.tenantId),
    ]);

    let userId = res[0];
    let tenantId = res[1];

    if ((userId === null || tenantId === null) && skipGetLoginOnFailure) {
      return { userId: null, tenantId: null };
    }

    if (userId === null || tenantId === null) {
      const result = await this.getLogin();
      if (result.success) {
        const updateRes = await Promise.all([
          this.store.getNumber(StorageKey.userId),
          this.store.getNumber(StorageKey.tenantId),
        ]);
        userId = updateRes[0];
        tenantId = updateRes[1];
      } else {
        return Promise.reject("Error determining user, try again.");
      }
    }

    return { userId: userId, tenantId: tenantId };
  }

  /**
   * Checks and gets the CLIENT type user IDs.
   *
   * @param {boolean} skipGetLoginOnFailure will return null values and not attempt to call GetLogin
   *
   * @return {Promise<{ userId: number | null; tenantId: number | null; }>} General user IDs.
   */
  async checkAndGetClientIds(skipGetLoginOnFailure?: boolean): Promise<{
    userId: number | null;
    tenantId: number | null;
  }> {
    const res = await Promise.all([
      this.store.getNumber(StorageKey.clientUserId),
      this.store.getNumber(StorageKey.clientTenantId),
      this.getEmail(),
    ]);

    let userId = res[0];
    let tenantId = res[1];
    const email = res[2];

    if (userId === null && tenantId !== null && skipGetLoginOnFailure) {
      return { userId: null, tenantId: null };
    }

    if ((userId === null || tenantId === null) && email !== null) {
      const result = await this.getClientLogin(email!);
      if (result.success) {
        const updateRes = await Promise.all([
          this.store.getNumber(StorageKey.clientUserId),
          this.store.getNumber(StorageKey.clientTenantId),
        ]);
        userId = updateRes[0];
        tenantId = updateRes[1];
      } else {
        return Promise.reject("Error determining user, try again.");
      }
    }

    return { userId: userId, tenantId: tenantId };
  }

  /**
   *
   */
  async getUsername(): Promise<string> {
    const value = await this.store.getString(StorageKey.username);
    return value || "";
  }
  /**
   * Give app 10 seconds to load premium
   * @returns {boolean} premium value
   */
  async awaitPremium(): Promise<boolean> {
    if (!environment.production) {
      console.log("Awaiting premium");
    }
    if (this.premium !== undefined) {
      return this.premium;
    }
    if (this.premium === undefined) {
      const result = await this.store.getNumber(StorageKey.tier);
      if (result !== null) {
        this.tier = result;
        return this.tier > 0;
      } else {
        // Now attempt to get details from Login
        await this.getLogin();
      }
    }
    if (this.premium === undefined) {
      const secondsToWait = 10;
      for (let i = 0; i < secondsToWait; i++) {
        if (this.premium !== undefined) break;
        await new Promise((resolve) =>
          setTimeout(resolve, secondsToWait * 100)
        );
      }
    }
    return Promise.resolve(this.premium || false);
  }
  async awaitAdmin(): Promise<boolean> {
    try {
      if (this._admin !== undefined) {
        return this._admin;
      } else {
        const result = await this.store.getNumber(StorageKey.admin);
        this._admin = result === 1;
        return this._admin;
      }
    } catch (e) {
      return false;
    }
  }
  /**
   * Get Firebase Auth Email
   * @returns
   */
  async getEmail(): Promise<string | null> {
    return new Promise((resolve) => {
      const auth = getAuth();
      auth.onAuthStateChanged((user) => {
        if (user == null) {
          resolve(null);
        } else {
          resolve(user.email);
        }
      });
    });
  }
  /**
   * Get Firebase Auth Email
   * @returns
   */
  async getCurrentUser(): Promise<User | null> {
    return new Promise((resolve) => {
      const auth = getAuth();
      auth.onAuthStateChanged((user) => {
        if (user == null) {
          resolve(null);
        } else {
          resolve(user);
        }
      });
    });
  }
  /**
   * Get the token from firebase
   * @returns
   */
  async getUserToken(): Promise<string | null> {
    return new Promise((resolve) => {
      const auth = getAuth();
      auth.onAuthStateChanged(async (user) => {
        if (user != null) {
          const token = await user?.getIdToken();
          resolve(token);
        }
        resolve(null);
      });
    });
  }
  /**
   * Returns basic user information for account page and saves it to device
   * @returns {IHttpLogin | null} User data if user is found, otherwise null
   * @since 1.2.0
   */
  async getLogin(): Promise<{
    success: boolean;
    message: string;
    user: IHttpLogin | null;
  }> {
    if (!environment.production) {
      console.log("getLogin(): /Login");
    }

    try {
      const token = await this.getUserToken();

      const response = await CapacitorHttp.request({
        method: "POST",
        url: environment.apiBaseUrl + "Login",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": environment.apiSubscriptionKey,
          "Ocp-Apim-Trace": "false",
          token: token || "",
        },
      });

      if (!response.data || response.data.success === false) {
        return { success: false, message: "Unknown Error - Login", user: null };
      }

      const { success, message, data } = response.data;
      const user: IHttpLogin | null = data;

      if (user) {
        await Promise.all([
          this.store.set(StorageKey.userId, user.id),
          this.store.set(
            StorageKey.username,
            `${user.firstname} ${user.lastname}`.trim()
          ),
          this.store.set(StorageKey.tenantId, user.tenantId),
          this.store.set(StorageKey.tier, user.tier ? user.tier : 0),
          this.store.set(StorageKey.enabled, user.archived ? 0 : 1),
        ]);

        // Save archived state as `enabled`
        this.enabled = !user.archived;
        this.tier = user.tier;

        this.evaluateSubscription({
          tier: user.tier,
          admin: null,
          enabled: !user.archived,
          defectLimit: user.defectLimit,
          defectCount: user.defectCount,
        });

        const fbUser = await this.getCurrentUser();
        if (fbUser !== null) {
          this.log.identifyUser({
            email: fbUser.email,
            id: fbUser.uid,
            paid: user.tier !== 0,
          });
        }
      }

      return Promise.resolve({ success, message, user });
    } catch (ex: any) {
      const loginError: any = ex?.message ?? "";
      if (loginError.includes("Unable to retrieve login")) {
        return Promise.resolve({
          success: true,
          message: "Unable to retrieve login",
          user: null,
        });
      } else {
        return Promise.reject(ex ?? "Unknown Exception - Login");
      }
    }
  }
  /**
   * Returns basic user information for account page and saves it to device
   * @returns {IHttpLogin | null} User data if user is found, otherwise null
   * @since 1.2.0
   */
  async getClientLogin(email: string): Promise<{
    success: boolean;
    message: string;
    user: IHttpClientLogin | null;
  }> {
    if (!environment.production) {
      console.log("getClientLogin(): /Login");
    }

    try {
      const token = await this.getUserToken();

      const response = await CapacitorHttp.request({
        method: "POST",
        url: environment.apiBaseUrl + "ClientLogin",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": environment.apiSubscriptionKey,
          "Ocp-Apim-Trace": "false",
          token: token || "",
        },
        data: {
          email,
        },
      });

      if (response.status === 404) {
        throw new Error("Not authorised to access this page.");
      }

      if (!response.data || response.data.success === false) {
        throw { success: false, message: response.data.message, user: null };
      }

      const { success, message, data } = response.data;
      const user: IHttpClientLogin | null = data;

      if (user) {
        await Promise.all([
          this.store.set(StorageKey.clientUserId, user.userId),
          this.store.set(StorageKey.clientTenantId, user.tenantId),
          this.store.set(
            StorageKey.username,
            `${user.firstname} ${user.lastname}`.trim()
          ),
          this.store.set(StorageKey.tier, user.tier ? user.tier : 0),
          // Save archived state as `enabled`
          this.store.set(StorageKey.enabled, 1),
        ]);

        this.tier = user.tier;

        this.evaluateSubscription({
          tier: user.tier,
          admin: false,
          enabled: true,
          defectLimit: user.defectLimit,
          defectCount: user.defectCount,
        });

        const fbUser = await this.getCurrentUser();
        if (fbUser !== null) {
          this.log.identifyUser({
            email: fbUser.email,
            id: fbUser.uid,
            paid: true,
          });
        }
      }

      return Promise.resolve({ success, message, user });
    } catch (ex: any) {
      const loginError: any = ex?.message ?? "";
      if (loginError.includes("Unable to retrieve login")) {
        return Promise.resolve({
          success: true,
          message: "Unable to retrieve login",
          user: null,
        });
      } else {
        await this.logout();
        return Promise.reject(ex ?? "Unknown Exception - Login");
      }
    }
  }
  /**
   *
   */
  async logout(): Promise<void> {
    await this.store.clear();
    const auth = getAuth();
    await auth.signOut();
  }
}

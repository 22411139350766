export enum DefectLogAction {
  unknown = "",
  /** Don't display any changes for created defects */
  created = "created",
  /** Default for all other actions */
  updated = "updated",
  /** Don't display any changes for synced defects */
  synced = "synced",
}

export type HistoryLogFieldName =
  | ""
  | "area"
  | "element"
  | "issue"
  | "status"
  | "priority"
  | "dueDate"
  | "completed"
  | "contractor"
  | "comments"
  | "created"
  | "photo";

export type DefectLogItem = {
  id: number;
  defectId: number;
  userId: number;
  actionTimestamp: Date;
  actionType: DefectLogAction;
  fieldName: HistoryLogFieldName;
  /** Original value */
  from: string;
  /** New value */
  to: string;
  userName: string;
};

export type DefectLogSet = {
  timestamp: Date;
  userName: string;
  actionType: DefectLogAction;
  items: DefectLogItem[];
};

export type DefectLog = DefectLogSet[];
